import { ECustomColNameProperty } from "@app/products/property/config";
import {
  getRaisePenaltyInterestLOVs,
  postRaisePenaltyInterest,
} from "@app/products/property/journals/components/form-elements/raise-penalty-interest/api";
import {
  DTO_Journal_RaisePenaltyInterest,
  eLoadingRaisePenalty,
} from "@app/products/property/journals/components/form-elements/raise-penalty-interest/model";
import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { DTO_LOV } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNote } from "@components/cc-note/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IRaisePenaltyInterestDialogProps {
  onClose: () => void;
}

const nameOfLOVs = nameOfFactory<DTO_LOV>();
export const RaisePenaltyInterestDialog = observer(
  ({ onClose }: IRaisePenaltyInterestDialogProps) => {
    // Label
    const assessmentLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Assessment
    );

    // State
    const [isLoading, setIsLoading] = useState(eLoadingRaisePenalty.None);
    const [confirmationData, setConfirmationData] = useState<
      DTO_Journal_RaisePenaltyInterest | undefined
    >(undefined);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [lovs, setLOVs] = useState<DTO_Journal_LOVs>();
    // Store
    const { pushNotification } = useCCAppNotificationStore();
    // Ref
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const instalmentPlan =
      convertValueLOVToNumber(lovs?.InstalmentPlans ?? [], "Code") ?? [];
    const handleGetLOVs = async () => {
      setIsLoading(eLoadingRaisePenalty.GetData);
      const response = await getRaisePenaltyInterestLOVs();
      if (isSuccessResponse(response) && response?.data) {
        setLOVs(response.data);
      } else {
        setResponseLoadError(response);
      }
      setIsLoading(eLoadingRaisePenalty.None);
    };

    const handleFinish = async (payload: DTO_Journal_RaisePenaltyInterest) => {
      setIsLoading(eLoadingRaisePenalty.Finish);
      const response = await postRaisePenaltyInterest(payload);
      if (isSuccessResponse(response) && response && response.data?.IsSuccess) {
        onClose();
        setConfirmationData(undefined);
        pushNotification({
          title:
            response.data.SuccessMessage ??
            "Journal - Raised Penalty Interest queued successfully.",
          type: "success",
        });
      } else {
        setConfirmationData(undefined);
        notificationRef?.current?.pushNotification({
          autoClose: false,
          title:
            response.data.ErrorMessage ??
            "Journal - Raised Penalty Interest queue failed.",
          type: "error",
        });
      }
      setIsLoading(eLoadingRaisePenalty.None);
    };

    useEffectOnce(() => {
      handleGetLOVs();
    });

    return (
      <>
        <Form
          render={(formRenderProps: FormRenderProps) => {
            const { modified, valid, valueGetter } = formRenderProps;
            return (
              <CCDialog
                onClose={onClose}
                maxWidth="40%"
                height="auto"
                titleHeader="Raise Penalty Interest"
                bodyElement={
                  isLoading === eLoadingRaisePenalty.GetData ? (
                    <Loading isLoading />
                  ) : responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={() => {
                        handleGetLOVs();
                      }}
                    />
                  ) : (
                    <FormElement className="cc-form">
                      <CCLocalNotification ref={notificationRef} />
                      <section className="cc-field-group">
                        <CCLabel title="Raise Penalty for the period" />
                        <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <CCLabel title="From Date" isMandatory />
                              <Field
                                name={"InterestFromDate"}
                                component={CCDatePicker}
                                validator={requiredValidator}
                                format={DATE_FORMAT.DATE_CONTROL}
                              />
                            </div>
                            <div className="cc-field">
                              <CCLabel title="To Date" isMandatory />
                              <Field
                                name={"InterestToDate"}
                                component={CCDatePicker}
                                validator={requiredValidator}
                                format={DATE_FORMAT.DATE_CONTROL}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <CCLabel title="Penalty instalment plan" />
                        <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <CCLabel
                                title="Instalment plan on which to raise penalty"
                                isMandatory
                              />
                              <Field
                                name={"InstalmentPlanId"}
                                component={CCSearchComboBox}
                                data={instalmentPlan ?? []}
                                dataItemKey={nameOfLOVs("Code")}
                                textField={nameOfLOVs("Name")}
                                validator={requiredValidator}
                                isUseDefaultOnchange
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                      <br />
                      <CCNote
                        message={`
                Raising Penalties will create a Journal with the penalty for each ${assessmentLabel}.
                  `}
                      />
                    </FormElement>
                  )
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button className="cc-dialog-button" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      themeColor="primary"
                      className={"cc-dialog-button"}
                      type="button"
                      name="btn-submit"
                      disabled={!modified || !valid}
                      onClick={() =>
                        setConfirmationData({
                          InterestFromDate: valueGetter("InterestFromDate"),
                          InterestToDate: valueGetter("InterestToDate"),
                          InstalmentPlanId: valueGetter("InstalmentPlanId"),
                        } as DTO_Journal_RaisePenaltyInterest)
                      }
                    >
                      Finish
                    </Button>
                  </div>
                }
              />
            );
          }}
        />
        {!isNil(confirmationData) ? (
          <ConfirmDialog
            onClosePopup={() => {
              setConfirmationData(undefined);
            }}
            title={"Confirmation"}
            onAsyncConfirm={() => {
              handleFinish(confirmationData);
            }}
            subMessage={
              "A Journal with the penalty will be created. Are you sure you want to submit?"
            }
            isLoadingYes={isLoading === eLoadingRaisePenalty.Finish}
          />
        ) : null}
      </>
    );
  }
);
