import { eventEmitter } from "@/App";
import { DTO_Workflow_Supplementary_AddMasterProperty } from "@app/products/property/assessments/master-properties/model";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { MASTER_PROPERTY_ACCORDION_GRID_ID } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/_index";
import { AddMasterPropertyDialog } from "@app/products/property/supplementary-rates/[id]/components/dialogs/add-master-property/_index";
import { postAddMasterProperty } from "@app/products/property/supplementary-rates/[id]/components/dialogs/add-master-property/api";
import { defaultWorkflowHeader } from "@app/products/property/supplementary-rates/[id]/components/dialogs/add-master-property/config";
import { URL_SEARCH_MASTER_PROPERTY } from "@app/products/property/supplementary-rates/[id]/components/dialogs/add-master-property/constants";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";

export const AddSupplementaryMasterPropertyButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [isLoadingFinish, setIsLoadingFinish] = useState<boolean>(false);

  const { pushNotification } = useCCAppNotificationStore();
  const { supplementaryAssessments, reLoadSupplementaryRates } =
    useSupplementaryRatesStore();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  const handleAddMasterProperty = async (data: any) => {
    const payload: DTO_Workflow_Supplementary_AddMasterProperty = {
      WorkflowHeader: defaultWorkflowHeader,
      WorkflowDetail: {
        SupplementaryId: supplementaryAssessments?.SupplementaryId,
        MasterPropertyId: data?.MasterProperty?.Id
          ? +data?.MasterProperty?.Id
          : 0,
      },
    };
    setIsLoadingFinish(true);
    const response = await postAddMasterProperty(payload);
    if (isSuccessResponse(response) && response && response.data?.IsSuccess) {
      setIsShowDialog(false);
      await reLoadSupplementaryRates(); //reload new record
      //Reload Master property accordion
      eventEmitter.emit(CCGridEventType.RefreshOData, {
        gridIds: [MASTER_PROPERTY_ACCORDION_GRID_ID],
      });
      pushNotification({
        title:
          response.data.SuccessMessage ??
          "The process to add the master property and associated assessments to the supplementary has been sent to the Job Queue",
        type: "success",
      });
    } else {
      //local notification
      notificationRef?.current?.pushNotification({
        autoClose: false,
        title:
          response.data.ErrorMessage ??
          "The process to add the master property and associated assessments to the supplementary could not be queued",
        type: "error",
      });
    }
    setIsLoadingFinish(false);
  };

  return (
    <>
      <PrivilegeWrapper
        dialogType={ePermissionPrivilegeTypeCheck.Workflow}
        privilegesEnum={EPrivilegesWorkflow.AddSupplementaryMasterProperty}
      >
        <CCNavButton
          title="Add"
          onClick={() => setIsShowDialog(true)}
          disabled={supplementaryAssessments?.SupplementaryDetail?.IsComplete}
        />
      </PrivilegeWrapper>
      {isShowDialog && (
        <AddMasterPropertyDialog
          handleFinish={handleAddMasterProperty}
          dataUrl={URL_SEARCH_MASTER_PROPERTY}
          notificationRef={notificationRef}
          onClose={() => setIsShowDialog(false)}
          isLoadingFinish={isLoadingFinish}
        />
      )}
    </>
  );
});
