import { AttachmentsStep } from "@app/core/communication/dialogs/components/form-elememts/attachment/_index";
import { MessageTemplateStep } from "@app/core/communication/dialogs/components/form-elememts/message-template/_index";
import { TemplateFormStep as CommunicationTemplateFormStep } from "@app/core/communication/dialogs/components/form-elememts/template/_index";
import { CommunicationToStep } from "@app/core/communication/dialogs/components/form-elememts/to/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { setUpMailMergeBatchRecall } from "@app/core/new-mail-merge/dialogs/util";
import { OptionsFormStep } from "@app/core/new-mail-merge/form-steps/options/_index";
import { TemplateFormStep } from "@app/core/new-mail-merge/form-steps/template/_index";
import { WorkflowFormStep } from "@app/core/new-mail-merge/form-steps/workflow/_index";
import { FormRecallStep } from "@app/core/recall/components/dialogs/batch-recall/steppers/form-recall";
import {
  handlePostingBatchRecall,
  handleSendingCommunicationForBatchRecall,
  handleSendingMailMergeForBatchRecall,
} from "@app/core/recall/components/dialogs/batch-recall/util";
import {
  BATCH_RECALL_INITIAL_FORM,
  BATCH_RECALL_STEP_KEYS as keys,
} from "@app/core/recall/constant";
import { BatchRecall_SendOption } from "@app/core/recall/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { INotificationPortalContentExcludeId } from "@components/cc-notification-portal/components/notification-portal-content/model";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import React, { useMemo, useRef, useState } from "react";
import "./_index.scss";

export const BatchRecallDialog = (props: IBatchRecallDialogProps) => {
  //#region props, states & refs
  const [recallIDs, setRecallIDs] = useState<number[]>([]);
  const [isStepProcessing, setIsStepProcessing] = useState(false);
  const [sendOptionTracking, setSendOptionTracking] =
    useState<BatchRecall_SendOption>(
      BATCH_RECALL_INITIAL_FORM.UIControl.SendOption
    );
  const [showConfirmQuit, setShowConfirmQuit] = useState(false);
  const mailMergeFirstLoadRef = useRef<() => void>();
  //#endregion

  //#region stores
  const { pushNotificationPortal, removeNotificationPortalByPlaceId } =
    useNotificationPortalStore();
  const { currentUserInfo } = useGlobalStore();
  //#endregion

  //#region component initialization
  const initialForm = useMemo(() => {
    const initialForm = Object.assign(
      {},
      BATCH_RECALL_INITIAL_FORM,
      setUpMailMergeBatchRecall(props.mailMergeDataset)
    );
    initialForm.Recall.Officer_ID = Number(currentUserInfo?.UserPKID) || null;
    initialForm.UIControl.Officer_Name = currentUserInfo?.userDisplayName ?? "";
    return initialForm;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialSteps: IStep[] = [
    {
      label: "Details",
      component: FormRecallStep,
      visible: initOptions.openWithCommunication,
      key: keys.Recall,
      options: {
        recallIDs,
      },
    },
    {
      label: "To",
      component: CommunicationToStep,
      visible: initOptions.openWithCommunication,
      key: keys.CommunicationRecipient,
      options: {
        RecordIds: recallIDs,
        ProductType: props.productType,
        RecordType: RECORDTYPE.CORE_Recall,
      },
      customClassName: "cc-batch-recall-form-full-h",
    },
    {
      label: "Message/Template",
      component: MessageTemplateStep,
      visible: initOptions.openWithCommunication,
      key: keys.CommunicationMessageTemplate,
    },
    {
      label: "Mail merge",
      component: CommunicationTemplateFormStep,
      visible: initOptions.openWithCommunication,
      key: keys.CommunicationMailMergeTemplate,
      options: {
        ProductType: props.productType,
      },
    },
    {
      label: "Attachments",
      component: AttachmentsStep,
      visible: initOptions.openWithCommunication,
      key: keys.CommunicationAttachment,
      options: {
        RecordIds: recallIDs,
        RecordTypeAttachment: RECORDTYPE.CORE_Recall,
      },
    },
    //#region Send option - Mail Merge
    {
      label: "Template",
      component: TemplateFormStep,
      visible: initOptions.openWithMailMerge,
      options: {
        isMultiple: props.mailMergeMultiple,
        productType: props.productType,
        recordType: RECORDTYPE.CORE_Recall,
        onFirstLoadRef: mailMergeFirstLoadRef,
      },
      customClassName: "cc-batch-recall-form-full-h",
      key: keys.MailMergeTemplate,
    },
    {
      label: "Options",
      component: OptionsFormStep,
      visible: initOptions.openWithMailMerge,
      key: keys.MailMergeOptions,
    },
    {
      label: "Workflow",
      component: WorkflowFormStep,
      visible: initOptions.openWithMailMerge,
      key: keys.MailMergeWorkflow,
    },
    //#endregion
  ];
  //#endregion

  //#region event handlers
  const handleStepOnNext = async (data: any) => {
    if (areRecallsSaved) {
      return true;
    }
    return handleSavingRecalls(data, {
      onSuccessKeepDialogOpen: data.UIControl.SendOption !== "none",
    });
  };

  const handleSavingRecalls = async (
    data: any,
    options?: {
      onSuccessKeepDialogOpen: boolean;
    }
  ) => {
    setIsStepProcessing(true);
    return await handlePostingBatchRecall(
      data,
      {
        productType: props.productType,
        recordType: props.recordType,
        recordIDs: props.recordIDs,
      },
      {
        onSuccess: (responseData: any) => {
          setRecallIDs(responseData.SuccessIDs);
          setIsStepProcessing(false);
          removeNotificationPortalByPlaceId(
            YieldNotificationPortal_BATCH_RECALL_DIALOG
          );
          const pushSuccessMessage = () => {
            const successNotification: INotificationPortalContentExcludeId = {
              type: "success",
              title: "Recalls saved successfully",
            };
            if (options?.onSuccessKeepDialogOpen) {
              successNotification.placeId =
                YieldNotificationPortal_BATCH_RECALL_DIALOG;
            }
            pushNotificationPortal(successNotification);
          };
          switch (data?.UIControl?.SendOption) {
            case "communication":
              // ? use timeout to get over notification being pushed while step component is unmounting.
              setTimeout(pushSuccessMessage);
              break;
            case "mailmerge":
              // ? mail merge step has its own effect on first load, leave the notification for it to push at the appropriate time.
              mailMergeFirstLoadRef.current =
                factoryMailMergeFirstLoad(pushSuccessMessage);
              break;
            case "none":
              pushSuccessMessage();
              break;
            default:
              break;
          }
        },
        onError: (response: any) => {
          setIsStepProcessing(false);
          pushNotificationPortal({
            type: "error",
            title: "Recalls saved failed",
            description: response.error,
            autoClose: false,
            placeId: YieldNotificationPortal_BATCH_RECALL_DIALOG,
          });
        },
      }
    );
  };

  const handleSubmissionSwitch = async (data: any) => {
    let result = true;
    switch (data?.UIControl?.SendOption) {
      case "communication":
        setIsStepProcessing(true);
        result = await handleSendingCommunicationForBatchRecall(data, {
          onSuccess: () => {
            removeNotificationPortalByPlaceId(
              YieldNotificationPortal_BATCH_RECALL_DIALOG
            );
            setIsStepProcessing(false);
            handleCloseConditionally({ force: true });
            pushNotificationPortal({
              type: "success",
              title: "Communication sent successfully",
            });
          },
          onError: (response) => {
            setIsStepProcessing(false);
            pushNotificationPortal({
              type: "error",
              title: "Communication sent failed",
              description: response?.data?.Errors,
              autoClose: false,
              placeId: YieldNotificationPortal_BATCH_RECALL_DIALOG,
            });
          },
        });
        break;
      case "mailmerge":
        setIsStepProcessing(true);
        result = await handleSendingMailMergeForBatchRecall(
          data,
          recallIDs,
          props.productType,
          {
            onSuccess: () => {
              removeNotificationPortalByPlaceId(
                YieldNotificationPortal_BATCH_RECALL_DIALOG
              );
              setIsStepProcessing(false);
              handleCloseConditionally({ force: true });
              pushNotificationPortal({
                title: "Mail merge sent successfully",
                type: "success",
              });
            },
            onError: (response) => {
              setIsStepProcessing(false);
              pushNotificationPortal({
                type: "error",
                title: response.statusText ?? "Mail merge sent failed",
                description: response.data?.Errors,
                autoClose: false,
                placeId: YieldNotificationPortal_BATCH_RECALL_DIALOG,
              });
            },
          }
        );
        break;
      case "none":
        if (!areRecallsSaved) {
          result = await handleSavingRecalls(data);
        }
        handleCloseConditionally({ force: true });
        break;
      default:
        break;
    }
    return result;
  };

  const onFormValueChange = (formData: typeof BATCH_RECALL_INITIAL_FORM) => {
    /**
     * !Warning: Adding too many component-update factors here may lead to performance issues.
     * Use this only for highly aware orders.
     */
    setSendOptionTracking(formData.UIControl.SendOption);
  };

  const handleCloseConditionally = (options?: { force: boolean }) => {
    if (areRecallsSaved && sendOptionTracking !== "none" && !options?.force) {
      setShowConfirmQuit(true);
      return;
    }
    if (typeof props.onClose === "function") {
      props.onClose();
    }
  };
  //#endregion

  //#region computed
  const areRecallsSaved = recallIDs.length > 0;
  //#endregion

  return (
    <>
      <CCFormStep
        initialValues={initialForm}
        initialSteps={initialSteps}
        onSubmit={handleSubmissionSwitch}
        saveOnNextStep={handleStepOnNext}
        onValueChange={onFormValueChange}
        listButtonId={["cc-finish-step-button"]}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="80%"
            maxHeight="85%"
            titleHeader={"Recall"}
            onClose={() => handleCloseConditionally()}
            disabled={isStepProcessing}
            bodyElement={renderProps.children}
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                <Button
                  className={"cc-dialog-button"}
                  onClick={() => handleCloseConditionally()}
                >
                  Cancel
                </Button>
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}

                {sendOptionTracking === "none" ? (
                  <Button
                    themeColor="primary"
                    id="cc-finish-step-button"
                    iconClass={isStepProcessing ? "fas fa-spinner fa-spin" : ""}
                    disabled={renderProps.nextButton.disabled}
                    className={"cc-dialog-button"}
                    onClick={renderProps.submitButton.onClick}
                  >
                    {"Finish"}
                  </Button>
                ) : (
                  <Button
                    themeColor="primary"
                    id="cc-next-step-button"
                    iconClass={isStepProcessing ? "fas fa-spinner fa-spin" : ""}
                    disabled={renderProps.nextButton.disabled}
                    className={"cc-dialog-button"}
                    onClick={renderProps.nextButton.onClick}
                  >
                    {renderProps.nextButton.label !== "Finish"
                      ? renderProps.nextButton.label
                      : "Send"}
                  </Button>
                )}
              </div>
            }
            yieldNotificationPortal={
              YieldNotificationPortal_BATCH_RECALL_DIALOG
            }
          />
        )}
      />
      {showConfirmQuit ? (
        <ConfirmDialog
          title="Confirmation"
          message="Process is not completed"
          subMessage="Recall details are already saved. If you leave now, further steps will not be processed."
          btnYesTitle="Ok"
          btnNoTitle="Cancel"
          onClosePopup={() => setShowConfirmQuit(false)}
          onConfirmYes={() => handleCloseConditionally({ force: true })}
        />
      ) : null}
    </>
  );
};

//#region internal use
interface IBatchRecallDialogProps {
  productType: PRODUCT_TYPE_NUMBER;
  recordType: RECORDTYPE;
  recordIDs: number[];
  mailMergeDataset: MAILMERGEDATASET;
  mailMergeMultiple?: boolean;
  onClose?: () => void;
}

const initOptions = {
  openWithCommunication:
    BATCH_RECALL_INITIAL_FORM.UIControl.SendOption === "communication",
  openWithMailMerge:
    BATCH_RECALL_INITIAL_FORM.UIControl.SendOption === "mailmerge",
};

const factoryMailMergeFirstLoad = (callback: Function) => {
  let executed = false;
  return () => {
    if (!executed) {
      executed = true;
      callback?.();
    }
  };
};

const YieldNotificationPortal_BATCH_RECALL_DIALOG = "batch-recall-dialog";
//#endregion
