import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useNewSupplementaryDialogStore } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/store";
import { PROPERTY_NOTICE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/constant";
import { AssessmentSelectionCriteriaFormStep } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/assessment-selection-criteria/_index";
import { useNoticeRunAssessmentStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/assessment-selection-criteria/store";
import { CollectionSelectionsFormStep } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/collection-selections/_index";
import { useNoticeRunCollectionStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/collection-selections/store";
import { ExecutionFormStep } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/execution/_index";
import { EExecutionOption } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/execution/config";
import { GroupsStatusesAndTypesFormStep } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/_index";
import { useNoticeRunGroupStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/store";
import { NoticeRunInstalmentsFormStep } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/instalments/_index";
import { EInstalmentPlanOption } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/instalments/config";
import { NoticeRunAttributesFormStep } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-run-attributes/_index";
import { useNoticeRunAttributeStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-run-attributes/store";
import { NoticeTypeFormStep } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-type/_index";
import { useNoticeRunNoticeTypeStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-type/store";
import { OptionsFormStep } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/options/_index";
import { useNoticeRunOptionStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/options/store";
import {
  getInitialDataNewNoticeRun,
  postNewNoticeRun,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/api";
import {
  dataWhichJournalNoticeRun,
  dataWhichSupplementaryNoticeRun,
  listConfigStepCreateNoticeRun,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/config";
import { useNoticeRunStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/store";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { WhichJournalFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/_index";
import { JournalMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/model";
import { WhichSupplementaryFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/_index";
import { SupplementaryMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { INewProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import { useNewJournalStepStore } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/store";
import { JournalStatus } from "@app/products/property/journals/model";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
} from "@app/products/property/model";
import {
  convertValueLOVToNumber,
  isFieldVisible,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { getDropdownValue } from "@common/utils/common";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { cloneDeep, isNil, pickBy } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import {
  DTO_NoticeRun_LOVs,
  DTO_Workflow_NoticeRunCreate,
  EKeysOfStepsNewNoticeRun,
  ELabelNewNoticeRun,
  IListConfigStepCreateNoticeRun,
  NOTICE_RUN,
  keysOfStepsNewNoticeRun,
} from "./model";

interface INewNoticeRunDialog {
  onClose: () => void;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  isRedirectManagePage?: boolean;
}
export const NewNoticeRunDialog = ({
  onClose,
  isSaveOnNextStep = false,
  prefixTitle,
  suffixTitle,
  dataFromActionList,
  isRedirectManagePage = true,
}: INewNoticeRunDialog) => {
  //#region <Store>
  const { setNoticeTypeLOVs, noticeTypeLOVs, setDataSelectedNoticeType } =
    useNoticeRunNoticeTypeStepStore();
  const { setNoticeRunOriginLOVs, noticeRunOriginLOVs } = useNoticeRunStore();
  const { setAssessmentLOVs } = useNoticeRunAssessmentStepStore();
  const { setNewJournalStepLOVs } = useNewJournalStepStore();
  const { setOptionLOVs } = useNoticeRunOptionStepStore();
  const { setGroupLOVs } = useNoticeRunGroupStepStore();
  const { setAttributeLOVs } = useNoticeRunAttributeStepStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { setDataForCancelDialog } = useConfirmCancelDialogStore();
  const { setDataForFinishDialog } = useConfirmFinishDialogStore();
  const { setDataForCloseDialog, setIsLoadingClose } =
    useConfirmCloseDialogStore();
  const { setCollectionLOVs } = useNoticeRunCollectionStepStore();
  const { setCreateSupplementaryLOVs } = useNewSupplementaryDialogStore();
  const { currentFormTitle } = useCommonProductStore();
  const {
    isIncompleteMode,
    isReadOnly,
    statusBadge,
    isShowCancelWorkflowButton,
    isToBeApprovalMode,
    isFromActionList,
  } = usePropertyWorkflow(dataFromActionList);
  //#endregion

  //#region <Local state>
  const history = useHistory();
  const formStepRef = useRef<ICCFormStepNotificationHandle | null>(null);
  const localNotificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [workflowInitData, setWorkflowInitData] =
    useState<DTO_Workflow_NoticeRunCreate>();
  const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
  const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
  const [isDisabledDialog, setIsDisabledDialog] = useState<boolean>(false);
  const [isLoadingProcess, setIsLoadingProcess] = useState<
    WorkflowProcessMode | undefined
  >();
  const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
    WorkflowDraft: { Workflow_Draft_Id: 0 },
    AvailableSecondaryWorkflows: [],
    WorkflowApprovals: [],
  });
  const [selectedTypeId, setSelectedTypeId] = useState<number>();
  //#endregion

  //#region <Title header>
  const [titleHeader, setTitleHeader] = useState<string>();

  const defaultTitle = useMemo(() => {
    const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
    const title =
      (currentFormTitle(formId ?? 0) || NOTICE_RUN) +
      (titleHeader ? ` - ${titleHeader}` : "");

    return getTitleWorkflow(title, prefixTitle, suffixTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefixTitle, suffixTitle, workflowHeader, titleHeader]);

  //#region

  //Get label
  const assessmentLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessment
  );

  //#region <Initial value>
  const initialValue = useMemo((): any => {
    //step Notice type
    const noticeTypeId = workflowInitData?.WorkflowDetail?.Notice_Type_Id;
    const listNoticeType = noticeTypeLOVs?.NoticeTypes?.length
      ? [...noticeTypeLOVs?.NoticeTypes]
      : [];
    let selectedNoticeList = [listNoticeType?.[0]];
    if (noticeTypeId === undefined || noticeTypeId !== 0) {
      selectedNoticeList = listNoticeType.filter(
        (item) => item.Code === noticeTypeId
      );
    }
    setDataSelectedNoticeType(selectedNoticeList?.[0]);
    let initialNoticeType = {
      Notice_Type_Id: selectedNoticeList?.[0]?.Code,
      _option: {
        SelectedNoticeType: selectedNoticeList,
      },
    };
    //step Attribute
    let initialAttribute = {
      ...workflowInitData?.WorkflowDetail?.Attributes,
    };
    //step Instalment
    let initialInstalment = {
      _option: {
        SelectionByInstalmentPlan: workflowInitData?.WorkflowDetail
          ?.InstalmentPlans?.SpecificRange
          ? EInstalmentPlanOption.SelectInstalment
          : EInstalmentPlanOption.NoSelection,
      },
      ...workflowInitData?.WorkflowDetail?.InstalmentPlans,
      InstalmentPlans: workflowInitData?.WorkflowDetail?.InstalmentPlans
        ?.SpecificRange
        ? workflowInitData?.WorkflowDetail?.InstalmentPlans?.InstalmentPlans ??
          []
        : [],
    };
    //step Journal
    const journals = workflowInitData?.WorkflowDetail?.Journals;
    const listJournalSelected = journals?.length
      ? journals.map((item: number) => ({ Journal_Number: item }))
      : [];
    let initialWhichJournal: any = {
      // If the API returns an unsupported value like null, undefined, or enum 0 (CreateNew),
      // we default to SelectFromMyList enum for compatibility with journal options in UX.
      JournalMode:
        workflowInitData?.WorkflowDetail?.JournalMode ||
        JournalMode.SelectFromMyList,

      JournalSelected: listJournalSelected,
    };
    //step New journal
    let initialNewJournal: any = {
      ...workflowInitData?.WorkflowDetail?.CreateJournal,
    };
    //step Supplementary
    const listsupplementary = workflowInitData?.WorkflowDetail?.Supplementaries;
    let initialSupplementary: any = {
      _options: {
        // If the API returns an unsupported value like null, undefined, or enum 0 (None),
        // we default to SelectFromMyList enum for compatibility with supplementary options in UX.
        WhichSupplementary:
          workflowInitData?.WorkflowDetail?.SupplementaryMode ||
          SupplementaryMode.SelectFromMyList,
      },
      Supplementary_Id: listsupplementary?.length ? listsupplementary?.[0] : 0,
      NewSupplementary:
        workflowInitData?.WorkflowDetail?.SupplementaryMode ===
        SupplementaryMode.CreateNew,
      SupplementarySelected: listsupplementary?.length
        ? [
            {
              Supplementary_Id: listsupplementary?.[0],
            },
          ]
        : [],
    };

    //step New supplementary
    let initialNewSupplementary = {
      ...workflowInitData?.WorkflowDetail?.CreateSupplementary,
    };
    //step Assessment
    let initialAssessment = {
      ...workflowInitData?.WorkflowDetail?.Assessment,
      Instalment_Plan_Id:
        workflowInitData?.WorkflowDetail?.Assessment?.Instalment_Plan_Id ??
        noticeRunOriginLOVs?.RatingPeriodId,
    };
    //step Group
    let initialGroup = {
      ...workflowInitData?.WorkflowDetail?.Groups,
    };
    //step Collection
    let initialCollection = {
      ...workflowInitData?.WorkflowDetail?.Collections,
    };
    //step Option
    let initialOption = { ...workflowInitData?.WorkflowDetail?.Options };
    //step Execution
    let initialExecution = {
      ...workflowInitData?.WorkflowDetail?.Execution,
      _option: {
        ExecutionOption: workflowInitData?.WorkflowDetail?.Execution
          ?.RunImmediately
          ? EExecutionOption.RunImmediately
          : EExecutionOption.ScheduleToRunAt,
      },
    };

    //reset turn on off stepper
    const noticeType = selectedNoticeList?.[0];
    if (formStepRef.current?.setStepsVisible) {
      const listVisibleStep = listConfigStepCreateNoticeRun.map(
        (step: IListConfigStepCreateNoticeRun) => {
          return {
            key: step.keyNameStep,
            visible: isFieldVisible(noticeType[step.flagVisibilityStep]),
            isClearData: true,
          };
        }
      );
      formStepRef.current?.setStepsVisible(listVisibleStep);
    }

    const initialDataForm = {
      [EKeysOfStepsNewNoticeRun.NoticeType]: initialNoticeType,
      [EKeysOfStepsNewNoticeRun.Attribute]: initialAttribute,
      [EKeysOfStepsNewNoticeRun.Instalment]: initialInstalment,
      [EKeysOfStepsNewNoticeRun.Journal]: initialWhichJournal,
      [EKeysOfStepsNewNoticeRun.NewJournal]: initialNewJournal,
      [EKeysOfStepsNewNoticeRun.Supplementary]: initialSupplementary,
      [EKeysOfStepsNewNoticeRun.NewSupplementary]: initialNewSupplementary,
      [EKeysOfStepsNewNoticeRun.Assessment]: initialAssessment,
      [EKeysOfStepsNewNoticeRun.Group]: initialGroup,
      [EKeysOfStepsNewNoticeRun.Collection]: initialCollection,
      [EKeysOfStepsNewNoticeRun.Option]: initialOption,
      [EKeysOfStepsNewNoticeRun.Execution]: initialExecution,
      [EKeysOfStepsNewNoticeRun.Comment]: {},
    };
    return initialDataForm;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowInitData]);
  //#region

  //#region <Step>
  const steps: IStep[] = [
    {
      label: ELabelNewNoticeRun.NoticeType,
      component: NoticeTypeFormStep,
      visible: true,
      key: EKeysOfStepsNewNoticeRun.NoticeType,
      initialValues: initialValue[EKeysOfStepsNewNoticeRun.NoticeType],
      options: {
        isReadOnly,
      },
    },
    {
      label: ELabelNewNoticeRun.Attribute,
      component: NoticeRunAttributesFormStep,
      visible: false,
      key: EKeysOfStepsNewNoticeRun.Attribute,
      initialValues: initialValue[EKeysOfStepsNewNoticeRun.Attribute],
      options: {
        isReadOnly,
        setIsDisabledDialog,
      },
    },
    {
      label: ELabelNewNoticeRun.Instalment,
      component: NoticeRunInstalmentsFormStep,
      visible: false,
      key: EKeysOfStepsNewNoticeRun.Instalment,
      initialValues: initialValue[EKeysOfStepsNewNoticeRun.Instalment],
      options: {
        isReadOnly,
      },
    },
    {
      label: ELabelNewNoticeRun.Journal,
      component: WhichJournalFormStep,
      visible: false,
      key: EKeysOfStepsNewNoticeRun.Journal,
      initialValues: initialValue[EKeysOfStepsNewNoticeRun.Journal],
      options: {
        isReadOnly,
        isNoticeRunJournal: true,
        journalOptions: dataWhichJournalNoticeRun,
        journalStatus: JournalStatus.Authorised,
        allowMultipleSelection: true,
      },
    },
    {
      label: ELabelNewNoticeRun.Supplementary,
      component: WhichSupplementaryFormStep,
      initialValues: initialValue[EKeysOfStepsNewNoticeRun.Supplementary],
      visible: false,
      options: {
        isReadOnly,
        supplementaryOptions: dataWhichSupplementaryNoticeRun,
        isFilteringCompletedSupp: true,
      },
      key: EKeysOfStepsNewNoticeRun.Supplementary,
    },
    {
      label: assessmentLabel,
      component: AssessmentSelectionCriteriaFormStep,
      visible: false,
      key: EKeysOfStepsNewNoticeRun.Assessment,
      initialValues: {},
      options: {
        isReadOnly,
      },
    },
    {
      label: ELabelNewNoticeRun.Group,
      component: GroupsStatusesAndTypesFormStep,
      visible: false,
      key: EKeysOfStepsNewNoticeRun.Group,
      initialValues: initialValue[EKeysOfStepsNewNoticeRun.Group],
      options: {
        isReadOnly,
      },
    },
    {
      label: ELabelNewNoticeRun.Collection,
      component: CollectionSelectionsFormStep,
      visible: false,
      key: EKeysOfStepsNewNoticeRun.Collection,
      initialValues: initialValue[EKeysOfStepsNewNoticeRun.Collection],
      options: {
        isReadOnly,
      },
    },
    {
      label: ELabelNewNoticeRun.Option,
      component: OptionsFormStep,
      visible: false,
      key: EKeysOfStepsNewNoticeRun.Option,
      initialValues: initialValue[EKeysOfStepsNewNoticeRun.Option],
      options: {
        isReadOnly,
      },
    },
    {
      label: ELabelNewNoticeRun.Execution,
      component: ExecutionFormStep,
      visible: true,
      key: EKeysOfStepsNewNoticeRun.Execution,
      initialValues: initialValue[EKeysOfStepsNewNoticeRun.Execution],
      options: {
        isReadOnly,
      },
    },
    {
      label: ELabelNewNoticeRun.Comment,
      initialValues: initialValue[EKeysOfStepsNewNoticeRun.Comment],
      component: CommentsFormStep,
      visible: true,
      key: EKeysOfStepsNewNoticeRun.Comment,
      customClassName: "cc-comment-step-fixed-height-grid",
      options: {
        isReadOnly,
        workflowDraftId,
        recordType: RECORDTYPE.CommunityProperty_Notice_Run_Assessment,
      },
    },
  ];
  //#endregion

  //#region <Handle submit>
  const handleSubmit = async (data: any, buttonId?: string) => {
    switch (buttonId) {
      case EListSubmitButton.Finish:
        handleConfirmFinish(data);
        break;
      case EListSubmitButton.Cancel:
      case EListSubmitButton.ConfirmCloseNo:
        handleCancelButton(processData(data));
        break;
      case EListSubmitButton.ConfirmCloseYes:
        await handleParkProcess(
          processData(data),
          EListSubmitButton.ConfirmCloseYes
        );
        break;
      case EListSubmitButton.Park:
      case EListSubmitButton.Close:
        await handleParkProcess(processData(data));
        break;
    }
  };
  //#endregion

  //#region <Process data>
  const processData = (data: any): DTO_Workflow_NoticeRunCreate => {
    let workflowDetail: any = {
      Notice_Run_Id: 0,
      ComponentNumber: workflowInitData?.WorkflowDetail?.ComponentNumber,
    };
    const newData = cloneDeep(data);
    const sendSteps = pickBy(newData, (value, key) => {
      if (keysOfStepsNewNoticeRun.includes(key as EKeysOfStepsNewNoticeRun)) {
        return { [key]: value };
      }
    });
    for (const [key, value] of Object.entries(sendSteps)) {
      const dataStep = { ...value };
      if (dataStep && dataStep?._option) {
        delete dataStep._option;
        delete dataStep._options;
      }
      workflowDetail[key] = dataStep;
    }
    //notice type
    workflowDetail.Notice_Type_Id =
      data[`${EKeysOfStepsNewNoticeRun.NoticeType}`]?.Notice_Type_Id;
    //journal
    workflowDetail.JournalMode =
      data[`${EKeysOfStepsNewNoticeRun.Journal}`]?.JournalMode ?? 0;
    const selectedJournals =
      data[`${EKeysOfStepsNewNoticeRun.Journal}`]?.JournalSelected ?? [];
    workflowDetail.Journals =
      selectedJournals?.map((item: any) => item.Journal_Number) ?? [];
    //supplementary
    workflowDetail.SupplementaryMode =
      data[`${EKeysOfStepsNewNoticeRun.Supplementary}`]?._options
        ?.WhichSupplementary ?? 0;
    const selectedSupplementary =
      data[`${EKeysOfStepsNewNoticeRun.Supplementary}`]
        ?.SupplementarySelected ?? [];
    workflowDetail.Supplementaries =
      selectedSupplementary?.map((item: any) => item.Supplementary_Id) ?? [];
    return {
      WorkflowHeader: workflowHeader,
      WorkflowDetail: workflowDetail,
    };
  };
  //#endregion

  //#region <Handle process workflow>
  /**
   * common function
   * handle calling api with multiple process
   * @param props
   */
  const handleProcessWorkflow = async (
    props: INewProcessWorkflow<DTO_Workflow_NoticeRunCreate>
  ) => {
    const {
      actionSuccess,
      defaultFailedMessage,
      setLoading,
      modeProcess,
      payload,
    } = props;
    const response = await postNewNoticeRun(modeProcess, payload);
    setLoading();
    if (isSuccessResponse(response)) {
      if (response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        if (props?.actionFail) props?.actionFail(response);
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    } else {
      if (props?.actionFail) props?.actionFail(response);
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response?.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
    if (props?.actionClose) props.actionClose();
  };
  //#endregion

  //#region <Handle next button>
  /**
   * @param data
   * @param step
   * @param keyStep
   * @returns
   */
  const handleNextButton = async (data: any) => {
    const newData = { ...data };
    const processPayload = processData(newData);
    //send data to call api save
    return handleSaveAndNext(processPayload);
  };
  //#endregion

  //#region <Handle save and next>
  const handleSaveAndNext = async (
    payload: DTO_Workflow_NoticeRunCreate
  ): Promise<boolean> => {
    //check condition use for Save button
    setIsLoadingProcess(WorkflowProcessMode.Save);
    //Calling process Save at next button
    const response = await postNewNoticeRun(WorkflowProcessMode.Save, payload);
    setIsLoadingProcess(undefined);
    //set default notification
    const defaultSuccessMessage = "Create notice run was saved successfully.";
    const defaultFailedMessage = "Create notice run could not be saved.";
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      if (!isSaveOnNextStep) {
        onClose();
        pushNotification({
          title: response?.data?.Notification ?? defaultSuccessMessage,
          type: "success",
        });
      } else {
        // check is the first saving
        if (isFirstSave) {
          setIsFirstSave(false);
          //set current workflowDraft Id
          setWorkflowDraftId(response?.data?.ID ?? 0);
          // set payload to send
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: response?.data?.ID,
            },
          });
        }
      }
      return true;
    } else {
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
    return false;
  };
  //#endregion

  //#region <Handle finish process>
  /**
   * @param payload
   */
  const handleFinishProcess = async (payload: DTO_Workflow_NoticeRunCreate) => {
    //set loading button
    setIsLoadingProcess(WorkflowProcessMode.Finish);
    //props send to process workflow
    const finishProps: INewProcessWorkflow<DTO_Workflow_NoticeRunCreate> = {
      payload: payload,
      actionSuccess: (e) => {
        const notificationContent: IAppNotificationItemAddProps = {
          title:
            e?.SuccessMessage ??
            e?.Notification ??
            "Create notice run was created successfully.",
          type: "success",
        };
        onClose();
        isRedirectManagePage &&
          history.push(`${PROPERTY_NOTICE_RUNS_ROUTE}/${e?.Component_ID}`, {
            notification: notificationContent,
          });
        !isRedirectManagePage && pushNotification(notificationContent);
      },
      setLoading: () => {
        setIsLoadingProcess(undefined);
      },
      defaultFailedMessage: "Create notice run could not be created.",
      modeProcess: WorkflowProcessMode.Finish,
    };
    //calling api process workflow
    await handleProcessWorkflow(finishProps);
  };
  //#endregion

  //#region <Handle confirm finish>
  const handleConfirmFinish = (payload: any) => {
    const dataProcessed = processData(payload);
    const finishCallback = function async() {
      return handleFinishProcess(dataProcessed);
    };
    setDataForFinishDialog({
      finishCallback,
      confirmMessage:
        "The create notice run will be created based on the information provided. Are you sure you want to submit?",
    });
  };
  //#endregion

  //#region <Handle park process>
  /**
   * @param payload
   * @param mode
   */
  const handleParkProcess = async (
    payload: DTO_Workflow_NoticeRunCreate,
    mode?: EListSubmitButton
  ) => {
    //set loading button and dialog
    setIsLoadingProcess(WorkflowProcessMode.Park);
    mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
    const actionCloseRetainDialog = () => {
      setIsLoadingClose(false);
      setDataForCloseDialog();
    };
    //props send to process workflow
    const parkProps: INewProcessWorkflow<DTO_Workflow_NoticeRunCreate> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        pushNotification({
          title:
            e?.Notification ?? "Create notice run was parked successfully.",
          type: "success",
        });
        isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
      },
      setLoading: () => {
        setIsLoadingProcess(undefined);
      },
      actionClose: () => {
        mode === EListSubmitButton.ConfirmCloseYes && actionCloseRetainDialog();
      },
      defaultFailedMessage: "Create notice run could not be parked.",
      modeProcess: WorkflowProcessMode.Park,
    };
    //calling api process workflow
    await handleProcessWorkflow(parkProps);
  };
  //#endregion

  //#region <handle cancel button>
  const handleCancelButton = (data: DTO_Workflow_NoticeRunCreate) => {
    if (isFromActionList || !isFirstSave) {
      setDataForCancelDialog({
        cancelAPI: postNewNoticeRun,
        dataCancel: data,
        defaultSuccessMessage: "Create notice run was cancelled successfully",
        defaultErrorMessage: "Create notice run could not be cancelled.",
      });
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <Handle close dialog>
  /**
   * @param renderProps
   */
  const handleCloseDialog = (renderProps: ICCFormStepRender) => {
    if (!isFromActionList && !isFirstSave) {
      //Store submit event
      setDataForCloseDialog({
        closeCallback: renderProps.submitButton.onClick,
      });
    } else if (
      isIncompleteMode &&
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
    ) {
      onClose();
    } else if (
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Incomplete &&
      !isFirstSave
    ) {
      const newEvent = {
        currentTarget: { id: EListSubmitButton.Close },
      };
      renderProps.submitButton.onClick(newEvent);
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <Get workflow data>
  /**
   * load initValue for FormStep
   * call once time
   */
  const getWorkflowData = async () => {
    const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
    formStepRef?.current?.setLoadingFormStep(true);
    const response = await getInitialDataNewNoticeRun(workflowDraftId);
    if (Array.isArray(response)) {
      const [lovsNoticeRun, workflowData] = response;
      if (
        isSuccessResponse(lovsNoticeRun) &&
        isSuccessResponse(workflowData) &&
        lovsNoticeRun?.data &&
        workflowData?.data
      ) {
        const lovs = lovsNoticeRun.data;
        // set Lovs Notice Type step
        const instalmentPlan = convertValueLOVToNumber(
          lovs?.InstalmentPlans ?? [],
          "Code"
        );
        setNoticeRunOriginLOVs({
          RatingPeriodId: lovs?.RatingPeriodId,
          InstalmentPlanId: lovs?.InstalmentPlanId,
          RatingPeriods: convertValueLOVToNumber(
            lovs?.RatingPeriods ?? [],
            "Code"
          ),
          InstalmentPlans: instalmentPlan,
          FinancialGroups: convertValueLOVToNumber(
            lovs?.FinancialGroups ?? [],
            "Code"
          ),
          AssessmentGroups: convertValueLOVToNumber(
            lovs?.AssessmentGroups ?? [],
            "Code"
          ),
        });
        setNoticeTypeLOVs({
          NoticeTypes: convertValueLOVToNumber(lovs?.NoticeTypes ?? [], "Code"),
        });
        //set Lovs Attribute step
        setAttributeLOVs({
          ChargeRun: convertValueLOVToNumber(lovs?.ChargeRun ?? [], "Code"),
          RatingPeriods: convertValueLOVToNumber(
            lovs?.RatingPeriods ?? [],
            "Code"
          ),
          FinancialGroups: convertValueLOVToNumber(
            lovs?.FinancialGroups ?? [],
            "Code"
          ),
        });
        //set Lovs New Journal step
        setNewJournalStepLOVs({
          JournalTypes: convertValueLOVToNumber(
            lovs?.JournalTypes ?? [],
            "Code"
          ),
        });
        //set Lovs New Supplementary step
        setCreateSupplementaryLOVs({
          SupplementaryType: convertValueLOVToNumber(
            lovs?.SupplementaryTypes ?? [],
            "Code"
          ),
          InstallmentType: instalmentPlan,
          AssessmentType: convertValueLOVToNumber(
            lovs?.AssessmentType ?? [],
            "Code"
          ),
          ValuationTypeTobeUsed: convertValueLOVToNumber(
            lovs?.ValuationTypeTobeUsed ?? [],
            "Code"
          ),
        });
        //set Lovs Assessment step
        setAssessmentLOVs({
          InstalmentPlans: instalmentPlan,
          DirectDebitSchemes: convertValueLOVToNumber(
            lovs?.DirectDebitSchemes ?? [],
            "Code"
          ),
        });
        //set Lovs Groups step
        setGroupLOVs({
          AssessmentGroups: convertValueLOVToNumber(
            lovs?.AssessmentGroups ?? [],
            "Code"
          ),
          NoticeGroups: convertValueLOVToNumber(
            lovs?.NoticeGroups ?? [],
            "Code"
          ),
          AssessmentTypes: convertValueLOVToNumber(
            lovs?.AssessmentTypes ?? [],
            "Code"
          ),
          AssessmentStatus: convertValueLOVToNumber(
            lovs?.AssessmentStatuses ?? [],
            "Code"
          ),
        });
        //set Lovs Options step
        setOptionLOVs({
          FinancialAreas: convertValueLOVToNumber(
            lovs?.FinancialAreas ?? [],
            "Code"
          ),
          NoticeOrdering: convertValueLOVToNumber(
            lovs?.NoticeOrderings ?? [],
            "Code"
          ),
          DocumentTemplates: convertValueLOVToNumber(
            lovs?.DocumentTemplates ?? [],
            "Code"
          ),
        });
        //set Lovs Collection step
        setCollectionLOVs({
          Collections: convertValueLOVToNumber(lovs?.Collections ?? [], "Code"),
        });
        setWorkflowInitData(workflowData?.data);
        if (workflowData.data?.WorkflowHeader) {
          setWorkflowHeader(workflowData.data?.WorkflowHeader);
          setWorkflowDraftId(
            workflowData.data?.WorkflowHeader?.WorkflowDraft
              ?.Workflow_Draft_Id ?? 0
          );
        }
        formStepRef?.current?.setLoadingFormStep(false);
      } else {
        let responseError: APIResponse<
          DTO_NoticeRun_LOVs | DTO_Workflow_NoticeRunCreate | ResponsePacket
        > = response[0];
        if (!isSuccessResponse(response[1])) {
          responseError = response[1];
        }
        formStepRef?.current?.setLoadingFormStep(false);
        formStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error:
              (responseError.data as ResponsePacket)?.Errors ??
              "Load workflow failed",
          },
        });
      }
    } else {
      const responseError = response as APIResponse;
      formStepRef?.current?.setLoadingFormStep(false);
      formStepRef?.current?.setLoadFailedFormStep({
        onReload: () => getWorkflowData(),
        responseError: {
          status: responseError.status,
          error: "Load workflow failed",
        },
      });
    }
  };

  const handleChangeTitle = (values: any) => {
    if (
      values[EKeysOfStepsNewNoticeRun.NoticeType]?.Notice_Type_Id !==
      selectedTypeId
    ) {
      setTitleHeader(
        getDropdownValue(
          values[EKeysOfStepsNewNoticeRun.NoticeType]?.Notice_Type_Id,
          noticeTypeLOVs?.NoticeTypes ?? [],
          "Code"
        )?.Name || ""
      );
      setSelectedTypeId(
        values[EKeysOfStepsNewNoticeRun.NoticeType]?.Notice_Type_Id
      );
    }
  };
  //#endregion

  //#region <Use Effect calling initial data>
  useEffectOnce(() => {
    getWorkflowData();
  });
  //#region

  return (
    <>
      <CCFormStep
        ref={formStepRef}
        onSubmit={handleSubmit}
        onValueChange={handleChangeTitle}
        localNotificationRef={localNotificationRef?.current ?? null}
        listButtonId={listSubmitButton}
        initialSteps={steps}
        initialValues={initialValue}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            titleHeader={defaultTitle}
            onClose={() => handleCloseDialog(renderProps)}
            bodyElement={renderProps.children}
            badge={statusBadge}
            disabled={isLoadingProcess !== undefined || isDisabledDialog}
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                {isShowParkButton(isFromActionList, isIncompleteMode) && (
                  <Button
                    iconClass={
                      isLoadingProcess === WorkflowProcessMode.Park
                        ? "fas fa-spinner fa-spin"
                        : ""
                    }
                    className={"cc-dialog-button"}
                    id={EListSubmitButton.Park}
                    onClick={renderProps.submitButton.onClick}
                    disabled={
                      renderProps.nextButton.disabled ||
                      isLoadingProcess === WorkflowProcessMode.Park
                    }
                  >
                    Park
                  </Button>
                )}
                {isShowCancelWorkflowButton && (
                  <Button
                    className={"cc-dialog-button"}
                    disabled={!isNil(isLoadingProcess)}
                    id={EListSubmitButton.Cancel}
                    onClick={renderProps.submitButton.onClick}
                  >
                    Cancel
                  </Button>
                )}
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}
                {isToBeApprovalMode || isReadOnly ? (
                  !renderProps.isLastStep && (
                    <Button
                      themeColor="primary"
                      id={"cc-next-step-button"}
                      disabled={
                        isLoadingProcess === WorkflowProcessMode.Save ||
                        renderProps.nextButton.disabled
                      }
                      className={"cc-dialog-button"}
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Save
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingProcess === WorkflowProcessMode.Save
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )
                ) : (
                  <Button
                    themeColor="primary"
                    id={renderProps.nextButton.idButton}
                    disabled={
                      isLoadingProcess === WorkflowProcessMode.Save ||
                      renderProps.nextButton.disabled
                    }
                    className={"cc-dialog-button"}
                    iconClass={
                      isLoadingProcess === WorkflowProcessMode.Save
                        ? "fas fa-spinner fa-spin"
                        : ""
                    }
                    onClick={renderProps.nextButton.onClick}
                  >
                    {isLoadingProcess === WorkflowProcessMode.Save
                      ? "Saving"
                      : renderProps.nextButton.label}
                  </Button>
                )}
              </div>
            }
          />
        )}
      />
    </>
  );
};
