import { useNewSupplementaryDialogStore } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/store";
import { OfficerAndOfficerRegion } from "@app/products/property/components/fields/officer-and-officer-region/_index";
import { ECustomColNameProperty } from "@app/products/property/config";
import { nameOfLov } from "@app/products/property/model";
import { DTO_LOV_SupplementaryType } from "@app/products/property/supplementary-rates/components/form-elements/new-supplementary/model";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { DTO_LOV } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { AssessmentTypeName } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/modify-supplementary/model";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isToBeApprovalMode: false,
      isActro: false,
      workflowDraftId: 0,
    },
  }: IFormStepElement) => {
    const [dataForConfirmDialog, setDataForConfirmDialog] =
      useState<DTO_LOV_SupplementaryType>();
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { createSupplementaryLOVs: supplementaryLOVs } =
      useNewSupplementaryDialogStore();

    //Get label
    const assessmentLowercaseLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.AssessmentLowercase
    );

    const supplementaryConfig = useMemo(() => {
      const assessmentTypes = convertValueLOVToNumber<DTO_LOV>(
        supplementaryLOVs?.AssessmentType ?? [],
        nameOfLov("Code")
      );
      const valuationTypes = convertValueLOVToNumber<DTO_LOV>(
        supplementaryLOVs?.ValuationTypeTobeUsed ?? [],
        nameOfLov("Code")
      );
      const installmentTypes = convertValueLOVToNumber<DTO_LOV>(
        supplementaryLOVs?.InstallmentType ?? [],
        nameOfLov("Code")
      );
      const supplementaryTypes =
        convertValueLOVToNumber<DTO_LOV_SupplementaryType>(
          supplementaryLOVs?.SupplementaryType ?? [],
          nameOfLov("Code")
        );
      return {
        supplementaryTypes,
        assessmentTypes,
        valuationTypes,
        installmentTypes,
      };
    }, [supplementaryLOVs]);

    const isInstalmentPlanHidden = getDropdownValue(
      getFieldValue("SupplementaryType"),
      supplementaryLOVs?.InstallmentType ?? [],
      nameOfLov("Code")
    )?.IsInstalmentPlanHidden;

    const currentAssessmentTypeItem = getDropdownValue(
      getFieldValue("BalanceTransferMethodId"),
      supplementaryConfig.assessmentTypes,
      nameOfLov("Code")
    );

    const [isShowValuationTypeToBeUsed, setIsShowValuationTypeToBeUsed] =
      useState<boolean>(
        currentAssessmentTypeItem
          ? currentAssessmentTypeItem?.Name === AssessmentTypeName.ByValuation
          : false
      );

    const handleValidate = useCallback(
      (value: any) => {
        if (options?.readOnly || isInstalmentPlanHidden) return undefined;
        return requiredValidator(value);
      },
      [options?.readOnly, isInstalmentPlanHidden]
    );

    const selectedSupplementaryType = getDropdownValue(
      getFieldValue("SupplementaryType"),
      supplementaryConfig.supplementaryTypes,
      nameOfLov("Code")
    );

    const handleChangeSupplementaryType = (
      newType: DTO_LOV_SupplementaryType
    ) => {
      //Show confirm dialog if the new type is raise charge and the current type is not raise charge and vice versa
      if (
        (selectedSupplementaryType?.IsRaiseCharge && !newType?.IsRaiseCharge) ||
        (!selectedSupplementaryType?.IsRaiseCharge && newType?.IsRaiseCharge)
      ) {
        setDataForConfirmDialog(newType);
      } else {
        changeSupplementaryType(newType);
      }
    };

    const changeSupplementaryType = (newType: DTO_LOV_SupplementaryType) => {
      onChange(nameOf("SupplementaryType"), {
        value: newType?.Code,
      });
      if (newType.IsInstalmentPlanHidden) {
        onChange(nameOf("InstalmentPlanId"), { value: undefined });
        onChange(nameOf("UseAssessmentsExistingInstalmentPlan"), {
          value: false,
        });
        onChange(nameOf("BalanceTransferMethodId"), {
          value: undefined,
        });
        onChange(nameOf("BalanceTransferValuationType"), {
          value: undefined,
        });
      }
    };

    const handleConfirmChangeSupplementaryType = () => {
      if (!dataForConfirmDialog) return;
      changeSupplementaryType(dataForConfirmDialog);
      setDataForConfirmDialog(undefined);
    };
    return (
      <>
        <section className="cc-field-group">
          <div className="cc-field">
            <OfficerAndOfficerRegion
              formRenderProps={formRenderProps}
              nameOf={nameOf}
              isDisabled={options?.isReadOnly || options?.workflowDraftId}
            />
          </div>
        </section>
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Supplementary type" isMandatory />
              <Field
                name={nameOf("SupplementaryType")}
                component={CCSearchComboBox}
                data={supplementaryConfig.supplementaryTypes}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                disabled={options?.isReadOnly}
                value={selectedSupplementaryType}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                onChange={(event: ComboBoxChangeEvent) => {
                  if (event?.value) {
                    handleChangeSupplementaryType(event.value);
                  } else {
                    onChange(nameOf("SupplementaryType"), {
                      value: null,
                    });
                  }
                }}
                placeholder="Supplementary type"
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Supplementary name" isMandatory />
              <Field
                name={nameOf("SupplementaryName")}
                component={CCInput}
                placeholder="Supplementary name"
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                readOnly={options?.isReadOnly}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Supplementary reference" />
              <Field
                name={nameOf("SupplementaryReference")}
                placeholder="Supplementary reference"
                component={CCInput}
                readOnly={options?.isReadOnly}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Supplementary date" isMandatory />
              <Field
                name={nameOf("SupplementaryDate")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                disabled={options?.isReadOnly}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Instalment plan" isMandatory />
              <Field
                name={nameOf("InstalmentPlanId")}
                component={CCSearchComboBox}
                data={supplementaryConfig.installmentTypes}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                isUseDefaultOnchange
                validator={handleValidate}
                disabled={options?.isReadOnly}
                placeholder="Instalment plan"
              />
            </div>
            <div className="cc-field">
              <CCLabel
                title={`Use ${assessmentLowercaseLabel}s existing instalment plan`}
              />
              <Field
                name={nameOf("UseAssessmentsExistingInstalmentPlan")}
                component={CCSwitch}
                checked={getFieldValue("UseAssessmentsExistingInstalmentPlan")}
                disabled={options?.isReadOnly}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Balance transfer method" />
              <Field
                name={nameOf("BalanceTransferMethodId")}
                component={CCSearchComboBox}
                data={supplementaryConfig.assessmentTypes}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                value={getDropdownValue(
                  getFieldValue("BalanceTransferMethodId"),
                  supplementaryConfig.assessmentTypes,
                  nameOfLov("Code")
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("BalanceTransferMethodId"), {
                    value: event.target.value?.Code ?? null,
                  });
                  setIsShowValuationTypeToBeUsed(
                    event.target.value?.Name === AssessmentTypeName.ByValuation
                  );
                  if (
                    event.target.value?.Name !== AssessmentTypeName.ByValuation
                  ) {
                    onChange(nameOf("BalanceTransferValuationType"), {
                      value: undefined,
                    });
                  }
                }}
                disabled={options?.isReadOnly}
                placeholder="Balance transfer method"
              />
            </div>
            {(isShowValuationTypeToBeUsed ||
              currentAssessmentTypeItem?.Name ===
                AssessmentTypeName.ByValuation) && (
              <div className="cc-field">
                <CCLabel title="Balance transfer valuation type" />
                <Field
                  name={nameOf("BalanceTransferValuationType")}
                  component={CCSearchComboBox}
                  data={supplementaryConfig.valuationTypes}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  isUseDefaultOnchange
                  disabled={options?.isReadOnly}
                  placeholder="Balance transfer valuation type"
                />
              </div>
            )}
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Notes</label>
                <Field
                  name={nameOf("Notes")}
                  rows={4}
                  component={CCTextArea}
                  readOnly={options?.isReadOnly}
                  placeholder="Notes"
                />
              </div>
            </div>
          </div>
        </section>
        {dataForConfirmDialog && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={
              dataForConfirmDialog?.IsRaiseCharge
                ? "The Supplementary will begin to raise charges. Is this correct?"
                : "The Supplementary will no longer raise charges. Is this correct?"
            }
            onAsyncConfirm={handleConfirmChangeSupplementaryType}
            onClosePopup={() => setDataForConfirmDialog(undefined)}
          />
        )}
      </>
    );
  }
);
