import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { BatchRecallButtonListView } from "@app/core/recall/components/buttons/list-view-toolbar";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { BatchUpdateButton } from "@app/products/hm/components/action-bar/buttons/batch-update/_index";
import { NewPremiseButton } from "@app/products/hm/components/action-bar/buttons/new-premises/_index";
import { HMActionBarNavDropdown } from "@app/products/hm/components/action-bar/nav-dropdown/_index";
import { HMUrl } from "@app/products/hm/config";
import { PremiseContactsTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/contacts/_index";
import { PremiseDetailsTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/details/_index";
import { PremiseHistoryTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/history/_index";
import { HMPremisesTabMap } from "@app/products/hm/premises/[id]/components/reference-sidebar/map/_index";
import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import { colHMPremisesPCBA } from "@app/products/hm/premises/pcba/config";
import { generateHMPremisesPCBAColumnsBySetting } from "@app/products/hm/premises/pcba/utils";
import { premisesRoute } from "@app/products/hm/premises/route";
import {
  HMPremisesBookmark,
  nameOfHMPremisesView,
} from "@app/products/hm/premises/util";
import { hmRoute } from "@app/products/hm/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { getUUID } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export default observer(() => {
  //#region stores
  const { settings } = useCommonCoreStore();
  //#endregion

  //#region memorization
  const gridArgs = useMemo(() => {
    const gridId = getUUID();
    return {
      gridId,
      refreshIdsAfterDeletion: [gridId],
    };
  }, []);
  const gridColumns = useMemo(
    () =>
      generateHMPremisesPCBAColumnsBySetting({
        settings,
        defaultConfig: colHMPremisesPCBA,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  //#endregion

  //#region custom hooks
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.HealthManager_Form_Premises,
    productType: PRODUCT_TYPE_NUMBER.HealthManager,
  });
  //#endregion

  const pcbaLabel = getStringValueSetting(
    settings[ECorporateSettingsField.HealthManager_PCBAMenuLabel]
  );

  useCCListViewActionBar({
    title: hmRoute.name,
    leftComponents: [<HMActionBarNavDropdown category={premisesRoute.path} />],
    centerComponents: [
      <NewPremiseButton
        disabled={!checkPermissions(FormAction.CORE_ALLOW_NEW)}
      />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.HealthManager}
        dataSetFilter={MAILMERGEDATASET.HealthManager_Premises}
        recordType={RECORDTYPE.HealthManager_Premises}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.HealthManager}
        recordType={RECORDTYPE.HealthManager_Premises}
        keyField={nameOfHMPremisesView("ID")}
      />,
      <DeleteButton
        recordType={RECORDTYPE.HealthManager_Premises}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
        gridId={gridArgs.gridId}
        refreshGridIds={gridArgs.refreshIdsAfterDeletion}
      />,
      <CCNavButton title="Tools" type="more">
        <BatchUpdateButton gridId={gridArgs.gridId} />
      </CCNavButton>,
      <BatchRecallButtonListView
        productType={PRODUCT_TYPE_NUMBER.HealthManager}
        recordType={RECORDTYPE.HealthManager_Premises}
        mailMergeDataset={MAILMERGEDATASET.HealthManager_Recall}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PREMISES_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.HealthManager}
        recordType={RECORDTYPE.HealthManager_Premises}
        detail={HMPremisesBookmark.getBookmarkDetailDisplayName}
        displayName={HMPremisesBookmark.getBookmarkListViewDetailRecord}
        listViewDetail={() =>
          HMPremisesBookmark.getBookmarkListViewDetail(pcbaLabel ?? "PCBA")
        }
        listViewDisplayName={HMPremisesBookmark.getBookmarkDetailDisplayName}
      />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <PremiseDetailsTab /> },
      { title: "Map", component: <HMPremisesTabMap /> },
      { title: "Contacts", component: <PremiseContactsTab /> },
      {
        title: "History",
        component: <PremiseHistoryTab />,
      },
    ],
  });
  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        gridId={gridArgs.gridId}
        columnFields={gridColumns}
        state={{
          sort: [{ field: nameOfHMPremisesView("TradingName"), dir: "asc" }],
        }}
        dataUrl={HMUrl.GET_VIEW_HM_PREMISES_PCBA}
        primaryField={nameOfHMPremisesView("ID")}
      />
    </LoadingPermissionWrapper>
  );
});
