import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useLookupHelpDocument } from "@common/pages/settings/lookups/help-documents/_id/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const LookupHelpDocumentTabHistory = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { helpDocument } = useLookupHelpDocument();
  const attachmentId: number = helpDocument?.Attachment_ID ?? lastSelectedRow?.ID ?? 0;
  return (
    <Journal
      id={attachmentId}
      triggersReload={helpDocument}
      recordType={RECORDTYPE.CORE_Attachment}
    />
  );
});
