import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { UpdateLossOfEligibilityDateDialog } from "@app/products/property/assessments/[id]/components/dialogs/rebates/_index";
import { AdjustChargeBalancesDialog } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/_index";
import AdjustChargeDialog from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/_index";
import { ChangeOfOwnershipDialog } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/_index";
import { IConfirmRejectWorkflow } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/dialogs/confirm-reject/model";
import { NewAssessmentTransactionDialog } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/new-transaction/_index";
import { JournalAssessmentRaiseInterestDialog } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-assessment-interest/_index";
import { RaiseChargesDialog } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-charges/_index";
import { ReverseTransactionDialog } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/reverse-transaction/_index";
import { SplitAssessmentDialog } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/_index";
import { TransferTransactionDialog } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-transaction/_index";
import { ModifyComplianceDialog } from "@app/products/property/assessments/compliance/[id]/components/forms/existed/components/form-steps/modify-compliance/_index";
import { AssessmentLandRentActionDialog } from "@app/products/property/assessments/components/dialogs/assessment-land-rent-action/_index";
import { ModifyChargeDetailsDialog } from "@app/products/property/assessments/components/dialogs/modify-charge-details/_index";
import { AddRebateDialog } from "@app/products/property/assessments/components/form-steps/add-rebate/_index";
import { AmalgamateAssessmentDialog } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/_index";
import { AssociateAssessmentDialog } from "@app/products/property/assessments/components/form-steps/associate-assessment/_index";
import { DisassociateAssessmentDialog } from "@app/products/property/assessments/components/form-steps/disassociate-assessment/_index";
import { ModifyAssessmentDialog } from "@app/products/property/assessments/components/form-steps/modify-assessment/_index";
import { NewAssessmentDialog } from "@app/products/property/assessments/components/form-steps/new-assessment/_index";
import { ReactivateAssessmentDialog } from "@app/products/property/assessments/components/form-steps/reactivate-assessment/_index";
import { SubdivideConsolidateDialog } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/_index";
import { CreateDebtRecoveryExemptionDialog } from "@app/products/property/assessments/debt-recovery/candidates/components/action-bar/dialogs/create-debt-recovery-exemption/_index";
import { CreateDefermentDialog } from "@app/products/property/assessments/deferments/components/action-bar/dialogs/create-deferment/_index";
import { ModifyDefermentDialog } from "@app/products/property/assessments/deferments/components/action-bar/dialogs/modify-deferment/_index";
import { BulkLevyUpdateDialog } from "@app/products/property/assessments/list/components/form-steps/bulk-levy-update/_index";
import { AddToSupplementaryDialog } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/_index";
import { AddNewMasterPropertyDialog } from "@app/products/property/assessments/master-properties/components/form-steps/add-new-master-property/_index";
import { ModifyMasterPropertyDialog } from "@app/products/property/assessments/master-properties/components/form-steps/modify-master-property/_index";
import { NewRebateClaimDialog } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/form-steps/new-rebate-claim/_index";
import { LoadWorkflowModifyAreaResponsibility } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/components/load-workflow/_index";
import { ModifyCertificateDialog } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/_index";
import { NewCertificateDialog } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/_index";
import { ReplaceCertificateDialog } from "@app/products/property/certificates/[id]/components/form-steps/replace-certificate/_index";
import { EditChangeOfOwnershipDialog } from "@app/products/property/changes-of-ownership/components/form-steps/edit-change-of-ownership/_index";
import { UpdateLeaseTransferDialog } from "@app/products/property/changes-of-ownership/lease-transfers/components/form-steps/update-lease-transfers/_index";
import { UpdateNoticeOfSaleDialog } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/_index";
import { CreateChargeRunDialog } from "@app/products/property/charge-and-notice-runs/charge-runs/components/form-steps/create-charge-run/_index";
import { NewNoticeRunDialog } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/_index";
import { ModifyDebtRecoveryDialog } from "@app/products/property/components/action-bar/dialog/modify-debt-recovery/_index";
import { NewDebtRecoveryDialog } from "@app/products/property/components/action-bar/dialog/new-debt-recovery/_index";
import { ConfirmCancelDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/_index";
import { ConfirmCloseDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/_index";
import { ConfirmFinishDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/_index";
import { ConfirmReallocateDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/_index";
import { ConfirmRejectDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/_index";
import { ConfirmSendBackDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/_index";
import { PROPERTY_ACTIONS_REJECTED_LIST_ROUTE } from "@app/products/property/components/action-bar/property-workflow/constant";
import {
  ESecondaryWorkflowStatus,
  IListDialog,
  TypesActionsWorkflow,
  WorkflowApprovalStatus,
  WorkflowStatus,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { eComponent } from "@app/products/property/components/associations/model";
import { NewContactDialog } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/_index";
import { UpdateContactDialog } from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/_index";
import { DeferredDutyReverseTransactionDialog } from "@app/products/property/deferred-duty/[id]/components/forms/existed/components/form-steps/reverse-transaction/_index";
import { TransferDDTransactionDialog } from "@app/products/property/deferred-duty/[id]/components/forms/existed/components/form-steps/transfer-transaction/_index";
import { CreateDeferredDutyDialog } from "@app/products/property/deferred-duty/components/action-bar/dialogs/create-deferred-duty/_index";
import { ModifyDeferredDutyDialog } from "@app/products/property/deferred-duty/components/action-bar/dialogs/modify-deferred-duty/_index";
import { RaiseInterestDialog } from "@app/products/property/deferred-duty/components/action-bar/dialogs/raise-interest/_index";
import { WriteOffBalancesDialog } from "@app/products/property/deferred-duty/components/action-bar/form-steps/write-off-balances/_index";
import { AuthoriseJournalDialog } from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/authorise/_index";
import { AddDeferredDutyAccountTransactionDialog } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/_index";
import { AddAssessmentTransactionDialog } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/_index";
import { CreateJournalDialog } from "@app/products/property/journals/list/components/dialogs/create-journal/_index";
import { WorkflowTypes } from "@app/products/property/model";
import { ModifyParcelGISDialog } from "@app/products/property/parcels/[id]/components/forms/existed/components/dialogs/gis-reference/_index";
import { CreateParcelDialog } from "@app/products/property/parcels/components/form-steps/create-parcel/_index";
import { ModifyParcelDialog } from "@app/products/property/parcels/components/form-steps/modify-parcel/_index";
import { AmalgamatePICDialog } from "@app/products/property/pic/list/components/action-bar/form-steps/amalgamate-pic/_index";
import { ModifyPICDialog } from "@app/products/property/pic/list/components/action-bar/form-steps/modify-pic/_index";
import { CreatePICDialog } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/_index";
import { NewTransactionPICDialog } from "@app/products/property/pic/list/components/action-bar/form-steps/new-transaction/_index";
import { ReactivatePICDialog } from "@app/products/property/pic/list/components/action-bar/form-steps/reactivate-pic/_index";
import { AssessmentAdjustmentsDialog } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/_index";
import { ModifySupplementaryDialog } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/modify-supplementary/_index";
import { NewSupplementaryDialog } from "@app/products/property/supplementary-rates/components/form-elements/new-supplementary/_index";
import { ModifyGISReferenceTitleDialog } from "@app/products/property/titles/[id]/components/dialogs/modify-gis-reference-title/_index";
import { ModifyTitleDialog } from "@app/products/property/titles/list/components/action-bar/form-steps/modify-title/_index";
import { CreateTitleDialog } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/_index";
import {
  ePermissionPrivilegeTypeCheck,
  Privilege,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const nameOfWorkflowDraft = nameOfFactory<VO_Workflow_Draft>();

export const PropertyWorkflow = observer(() => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  //Store
  const { removeDialog, listDialog, resetData } = usePropertyWorkflowStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { gridSelectedRows, gridSelectedIds, lastSelectedId } =
    useCCProductListViewStore();

  useEffectOnce(() => {
    return () => resetData();
  });

  const componentDialog = (infoComponent: IListDialog) => {
    const { type: dialogEnum, props, data } = infoComponent;
    const { Workflow_Status: typeList } = data;
    //Check Privilege
    if (
      !Privilege.checkPermission(
        ePermissionPrivilegeTypeCheck.Workflow,
        Privilege.convertEnumWorkflowTypeToPrivilege(dialogEnum)
      )
    ) {
      pushNotification({
        type: "error",
        description: "You are not authorised for this action.",
        autoClose: false,
      });
      dialogEnum && removeDialog(dialogEnum);
      return;
    }
    if (!dialogEnum || !data) return null;
    const isStatusCompleted: boolean =
      typeList === TypesActionsWorkflow.ToBeApproved &&
      data?.Status === ESecondaryWorkflowStatus.Completed;

    /**
     * return Dialog
     */
    switch (dialogEnum) {
      case WorkflowTypes.Create_Assessment:
        let propsCreateAssessment = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Create_Assessment);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsCreateAssessment = {
            ...propsCreateAssessment,
            isSaveOnNextStep: true,
          };
          return <NewAssessmentDialog {...propsCreateAssessment} />;
        }
        //Using in workflow list
        propsCreateAssessment = {
          ...propsCreateAssessment,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <NewAssessmentDialog {...propsCreateAssessment} />;
      case WorkflowTypes.Modify_Assessment:
        let propsModifyAssessment = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Modify_Assessment);
          },
        };
        if (isNil(typeList)) {
          propsModifyAssessment = {
            ...propsModifyAssessment,
            isSaveOnNextStep: true,
            isModifyAssessmentMode: true,
            isShowCancelWorkflowButton: true,
          };
          return <ModifyAssessmentDialog {...propsModifyAssessment} />;
        }
        //Using in workflow list
        propsModifyAssessment = {
          ...propsModifyAssessment,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <ModifyAssessmentDialog {...propsModifyAssessment} />;
      case WorkflowTypes.Create_Parcel:
        let propsCreateParcel = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Create_Parcel);
          },
        };
        ///Using in the button
        if (!typeList) {
          propsCreateParcel = {
            ...propsCreateParcel,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <CreateParcelDialog {...propsCreateParcel} />;
        }
        //Using in workflow list
        propsCreateParcel = {
          ...propsCreateParcel,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <CreateParcelDialog {...propsCreateParcel} />;
      case WorkflowTypes.Modify_Parcel:
        let propsModifyParcel = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Modify_Parcel);
          },
        };
        if (isNil(typeList)) {
          propsModifyParcel = {
            ...propsModifyParcel,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <ModifyParcelDialog {...propsModifyParcel} />;
        }
        //Using in workflow list
        propsModifyParcel = {
          ...propsModifyParcel,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <ModifyParcelDialog {...propsModifyParcel} />;
      case WorkflowTypes.AssessmentAdjustment_Supplementary:
        let propsAssessmentAdjustmentsDialog = {
          ...props,
          onReject: (data: IConfirmRejectWorkflow) => {
            history.push(PROPERTY_ACTIONS_REJECTED_LIST_ROUTE);
            pushNotification({
              title: "Assessment was rejected successfully",
              type: "success",
            });
            removeDialog(WorkflowTypes.AssessmentAdjustment_Supplementary);
          },
          isStatusCompleted: isStatusCompleted,
          onClose: () => {
            removeDialog(WorkflowTypes.AssessmentAdjustment_Supplementary);
          },
        };

        if (isNil(typeList)) {
          propsAssessmentAdjustmentsDialog = {
            ...propsAssessmentAdjustmentsDialog,
            supplementaryId: id || 0,
            isSaveOnNextStep: true,
          };
          return (
            <AssessmentAdjustmentsDialog
              {...propsAssessmentAdjustmentsDialog}
            />
          );
        }

        //Using in workflow list
        propsAssessmentAdjustmentsDialog = {
          ...propsAssessmentAdjustmentsDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };

        return (
          <AssessmentAdjustmentsDialog {...propsAssessmentAdjustmentsDialog} />
        );
      case WorkflowTypes.Change_Of_Ownership:
        let defaultPropsChangeOfOwnership = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Change_Of_Ownership);
          },
        };
        if (isNil(typeList)) {
          defaultPropsChangeOfOwnership = {
            ...defaultPropsChangeOfOwnership,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <ChangeOfOwnershipDialog {...defaultPropsChangeOfOwnership} />;
        }
        //Using in workflow list
        defaultPropsChangeOfOwnership = {
          ...defaultPropsChangeOfOwnership,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
          componentNumber: eComponent.Workflow,
        };
        return <ChangeOfOwnershipDialog {...defaultPropsChangeOfOwnership} />;
      case WorkflowTypes.UpdateLeaseTransfer:
        let defaultPropsUpdateLeaseTransferDialogDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.UpdateLeaseTransfer);
          },
        };
        if (isNil(typeList)) {
          defaultPropsUpdateLeaseTransferDialogDialog = {
            ...defaultPropsUpdateLeaseTransferDialogDialog,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
            assessmentId: gridSelectedRows?.[0]?.Assessment_Id,
          };
          return (
            <UpdateLeaseTransferDialog
              {...defaultPropsUpdateLeaseTransferDialogDialog}
            />
          );
        }
        //Using in workflow list
        defaultPropsUpdateLeaseTransferDialogDialog = {
          ...defaultPropsUpdateLeaseTransferDialogDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <UpdateLeaseTransferDialog
            {...defaultPropsUpdateLeaseTransferDialogDialog}
          />
        );
      case WorkflowTypes.Modify_Parcel_GIS:
        let propsGISReferenceDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Modify_Parcel_GIS);
          },
        };
        if (isNil(typeList)) {
          propsGISReferenceDialog = {
            ...propsGISReferenceDialog,
            typeList: typeList,
            isShowCancelWorkflowButton: true,
          };
          return <ModifyParcelGISDialog {...propsGISReferenceDialog} />;
        }
        //Using in workflow list
        propsGISReferenceDialog = {
          ...propsGISReferenceDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
          typeList: typeList,
        };
        return <ModifyParcelGISDialog {...propsGISReferenceDialog} />;
      case WorkflowTypes.Create_MasterProperty:
        let defaultPropsCreateMasterProperty = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Create_MasterProperty);
          },
        };
        if (isNil(typeList)) {
          defaultPropsCreateMasterProperty = {
            ...defaultPropsCreateMasterProperty,
            isSaveOnNextStep: true,
            isNewMasterPropertyMode: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <AddNewMasterPropertyDialog {...defaultPropsCreateMasterProperty} />
          );
        }
        //Using in workflow list
        defaultPropsCreateMasterProperty = {
          ...defaultPropsCreateMasterProperty,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <AddNewMasterPropertyDialog {...defaultPropsCreateMasterProperty} />
        );
      case WorkflowTypes.Create_DebtRecovery:
        let defaultPropsCreateDebtRecoveryDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Create_DebtRecovery);
          },
        };
        if (isNil(typeList)) {
          defaultPropsCreateDebtRecoveryDialog = {
            ...defaultPropsCreateDebtRecoveryDialog,
            assessmentId: id || gridSelectedRows?.[0]?.Assessment_Id || 0,
            isSaveOnNextStep: true,
            isCreateDebtRecoveryMode: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <NewDebtRecoveryDialog {...defaultPropsCreateDebtRecoveryDialog} />
          );
        }
        //Using in workflow list
        defaultPropsCreateDebtRecoveryDialog = {
          ...defaultPropsCreateDebtRecoveryDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <NewDebtRecoveryDialog {...defaultPropsCreateDebtRecoveryDialog} />
        );
      case WorkflowTypes.ModifyDebtRecovery:
        let defaultPropsModifyDebtRecovery = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.ModifyDebtRecovery);
          },
        };
        if (isNil(typeList)) {
          defaultPropsModifyDebtRecovery = {
            ...defaultPropsModifyDebtRecovery,
            assessmentId: id || gridSelectedRows?.[0]?.Assessment_Id || 0,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <ModifyDebtRecoveryDialog {...defaultPropsModifyDebtRecovery} />
          );
        }
        //Using in workflow list
        defaultPropsModifyDebtRecovery = {
          ...defaultPropsModifyDebtRecovery,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <ModifyDebtRecoveryDialog {...defaultPropsModifyDebtRecovery} />;
      case WorkflowTypes.Edit_MasterProperty:
        let defaultPropsEditMasterProperty = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Edit_MasterProperty);
          },
        };
        if (isNil(typeList)) {
          defaultPropsEditMasterProperty = {
            ...defaultPropsEditMasterProperty,
            masterPropertyId: gridSelectedRows[0]?.Id || id || 0,
            isSaveOnNextStep: true,
            isModifyMasterPropertyMode: true,
            isShowSearchType: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <ModifyMasterPropertyDialog {...defaultPropsEditMasterProperty} />
          );
        }
        //Using in workflow list
        defaultPropsEditMasterProperty = {
          ...defaultPropsEditMasterProperty,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <ModifyMasterPropertyDialog {...defaultPropsEditMasterProperty} />
        );
      case WorkflowTypes.Associate_Supplementary:
        let defaultPropsAssociateSupplementary = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Associate_Supplementary);
          },
        };
        if (isNil(typeList)) {
          defaultPropsAssociateSupplementary = {
            ...defaultPropsAssociateSupplementary,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <AddToSupplementaryDialog {...defaultPropsAssociateSupplementary} />
          );
        }
        //Using in workflow list
        defaultPropsAssociateSupplementary = {
          ...defaultPropsAssociateSupplementary,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <AddToSupplementaryDialog {...defaultPropsAssociateSupplementary} />
        );
      case WorkflowTypes.Journal_Create:
        let defaultPropsJournalCreate = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Journal_Create);
          },
        };
        if (isNil(typeList)) {
          defaultPropsJournalCreate = {
            ...defaultPropsJournalCreate,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <CreateJournalDialog {...defaultPropsJournalCreate} />;
        }
        //Using in workflow list
        defaultPropsJournalCreate = {
          ...defaultPropsJournalCreate,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <CreateJournalDialog {...defaultPropsJournalCreate} />;
      case WorkflowTypes.Journal_Assessment_Raise_Charges:
        let defaultPropsJournalAssessmentRaiseCharges = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Journal_Assessment_Raise_Charges);
          },
        };
        if (isNil(typeList)) {
          defaultPropsJournalAssessmentRaiseCharges = {
            ...defaultPropsJournalAssessmentRaiseCharges,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <RaiseChargesDialog
              {...defaultPropsJournalAssessmentRaiseCharges}
            />
          );
        }
        //Using in workflow list
        defaultPropsJournalAssessmentRaiseCharges = {
          ...defaultPropsJournalAssessmentRaiseCharges,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <RaiseChargesDialog {...defaultPropsJournalAssessmentRaiseCharges} />
        );
      case WorkflowTypes.Journal_DD_Writeoff_Balance:
        let defaultPropsJournalDDWriteoffBalance = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Journal_DD_Writeoff_Balance);
          },
        };
        if (isNil(typeList)) {
          defaultPropsJournalDDWriteoffBalance = {
            ...defaultPropsJournalDDWriteoffBalance,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
            accountId: !isNil(id) ? parseInt(id) : undefined,
          };
          return (
            <WriteOffBalancesDialog {...defaultPropsJournalDDWriteoffBalance} />
          );
        }
        //Using in workflow list
        defaultPropsJournalDDWriteoffBalance = {
          ...defaultPropsJournalDDWriteoffBalance,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <WriteOffBalancesDialog {...defaultPropsJournalDDWriteoffBalance} />
        );
      case WorkflowTypes.Journal_Assessment_Add_Transaction:
        let defaultPropsJournalAssessmentAddTransaction = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Journal_Assessment_Add_Transaction);
          },
        };
        if (isNil(typeList)) {
          defaultPropsJournalAssessmentAddTransaction = {
            ...defaultPropsJournalAssessmentAddTransaction,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <NewAssessmentTransactionDialog
              {...defaultPropsJournalAssessmentAddTransaction}
            />
          );
        }
        //Using in workflow list
        defaultPropsJournalAssessmentAddTransaction = {
          ...defaultPropsJournalAssessmentAddTransaction,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <NewAssessmentTransactionDialog
            {...defaultPropsJournalAssessmentAddTransaction}
          />
        );
      case WorkflowTypes.Journal_Assessment_Transafer_Transaction:
        let defaultPropsJournalAssessmentTransaferTransaction = {
          ...props,
          onClose: () => {
            removeDialog(
              WorkflowTypes.Journal_Assessment_Transafer_Transaction
            );
          },
        };
        if (isNil(typeList)) {
          defaultPropsJournalAssessmentTransaferTransaction = {
            ...defaultPropsJournalAssessmentTransaferTransaction,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <TransferTransactionDialog
              {...defaultPropsJournalAssessmentTransaferTransaction}
            />
          );
        }
        //Using in workflow list
        defaultPropsJournalAssessmentTransaferTransaction = {
          ...defaultPropsJournalAssessmentTransaferTransaction,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <TransferTransactionDialog
            {...defaultPropsJournalAssessmentTransaferTransaction}
          />
        );
      case WorkflowTypes.Journal_Add_DDTransaction:
        let defaultPropsJournalAddDDTransaction = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Journal_Add_DDTransaction);
          },
        };
        if (isNil(typeList)) {
          defaultPropsJournalAddDDTransaction = {
            ...defaultPropsJournalAddDDTransaction,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <AddDeferredDutyAccountTransactionDialog
              {...defaultPropsJournalAddDDTransaction}
            />
          );
        }
        //Using in workflow list
        defaultPropsJournalAddDDTransaction = {
          ...defaultPropsJournalAddDDTransaction,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <AddDeferredDutyAccountTransactionDialog
            {...defaultPropsJournalAddDDTransaction}
          />
        );
      case WorkflowTypes.Journal_Assessment_Reverse_Transaction:
        let defaultPropsJournalAssessmentReverseTransaction = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Journal_Assessment_Reverse_Transaction);
          },
        };
        if (isNil(typeList)) {
          defaultPropsJournalAssessmentReverseTransaction = {
            ...defaultPropsJournalAssessmentReverseTransaction,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <ReverseTransactionDialog
              {...defaultPropsJournalAssessmentReverseTransaction}
            />
          );
        }
        //Using in workflow list
        defaultPropsJournalAssessmentReverseTransaction = {
          ...defaultPropsJournalAssessmentReverseTransaction,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <ReverseTransactionDialog
            {...defaultPropsJournalAssessmentReverseTransaction}
          />
        );
      case WorkflowTypes.Journal_Add_Transaction:
        let defaultPropsJournalAddTransaction = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Journal_Add_Transaction);
          },
        };
        if (isNil(typeList)) {
          defaultPropsJournalAddTransaction = {
            ...defaultPropsJournalAddTransaction,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <AddAssessmentTransactionDialog
              {...defaultPropsJournalAddTransaction}
            />
          );
        }
        //Using in workflow list
        defaultPropsJournalAddTransaction = {
          ...defaultPropsJournalAddTransaction,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <AddAssessmentTransactionDialog
            {...defaultPropsJournalAddTransaction}
          />
        );
      case WorkflowTypes.New_Supplementary:
        let defaultPropsNewSupplementary = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.New_Supplementary);
          },
        };
        if (isNil(typeList)) {
          defaultPropsNewSupplementary = {
            ...defaultPropsNewSupplementary,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <NewSupplementaryDialog {...defaultPropsNewSupplementary} />;
        }
        //Using in workflow list
        defaultPropsNewSupplementary = {
          ...defaultPropsNewSupplementary,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <NewSupplementaryDialog {...defaultPropsNewSupplementary} />;
      case WorkflowTypes.Supplementary_Modify:
        let defaultPropsModifySupplementary = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Supplementary_Modify);
          },
        };
        if (isNil(typeList)) {
          defaultPropsModifySupplementary = {
            ...defaultPropsModifySupplementary,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <ModifySupplementaryDialog {...defaultPropsModifySupplementary} />
          );
        }
        //Using in workflow list
        defaultPropsModifySupplementary = {
          ...defaultPropsModifySupplementary,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <ModifySupplementaryDialog {...defaultPropsModifySupplementary} />
        );
      case WorkflowTypes.Create_DeferredDuty:
        let defaultPropsCreateDeferredDuty = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Create_DeferredDuty);
          },
        };
        if (isNil(typeList)) {
          defaultPropsCreateDeferredDuty = {
            ...defaultPropsCreateDeferredDuty,
            isSaveOnNextStep: true,
            isNewMode: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <CreateDeferredDutyDialog {...defaultPropsCreateDeferredDuty} />
          );
        }
        //Using in workflow list
        defaultPropsCreateDeferredDuty = {
          ...defaultPropsCreateDeferredDuty,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <CreateDeferredDutyDialog {...defaultPropsCreateDeferredDuty} />;
      case WorkflowTypes.Modify_DeferredDuty:
        let defaultPropsModifyDeferredDuty = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Modify_DeferredDuty);
          },
        };
        if (isNil(typeList)) {
          defaultPropsModifyDeferredDuty = {
            ...defaultPropsModifyDeferredDuty,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
            accountId: id || lastSelectedId || undefined,
          };
          return (
            <ModifyDeferredDutyDialog {...defaultPropsModifyDeferredDuty} />
          );
        }
        //Using in workflow list
        defaultPropsModifyDeferredDuty = {
          ...defaultPropsModifyDeferredDuty,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <ModifyDeferredDutyDialog {...defaultPropsModifyDeferredDuty} />;
      case WorkflowTypes.Journal_Assessment_Raise_Interest:
        let defaultPropsJournalAssessmentRaiseInterest = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Journal_Assessment_Raise_Interest);
          },
        };
        if (isNil(typeList)) {
          defaultPropsJournalAssessmentRaiseInterest = {
            ...defaultPropsJournalAssessmentRaiseInterest,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <JournalAssessmentRaiseInterestDialog
              {...defaultPropsJournalAssessmentRaiseInterest}
            />
          );
        }
        //Using in workflow list
        defaultPropsJournalAssessmentRaiseInterest = {
          ...defaultPropsJournalAssessmentRaiseInterest,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <JournalAssessmentRaiseInterestDialog
            {...defaultPropsJournalAssessmentRaiseInterest}
          />
        );
      case WorkflowTypes.Journal_DD_Raise_Interest:
        let defaultPropsJournalDDRaiseInterest = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Journal_DD_Raise_Interest);
          },
        };
        if (isNil(typeList)) {
          defaultPropsJournalDDRaiseInterest = {
            ...defaultPropsJournalDDRaiseInterest,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
            deferredDutyId: id || lastSelectedId || undefined,
          };
          return (
            <RaiseInterestDialog {...defaultPropsJournalDDRaiseInterest} />
          );
        }
        //Using in workflow list
        defaultPropsJournalDDRaiseInterest = {
          ...defaultPropsJournalDDRaiseInterest,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <RaiseInterestDialog {...defaultPropsJournalDDRaiseInterest} />;
      case WorkflowTypes.Modify_Compliance:
        let defaultPropsModifyCompliance = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Modify_Compliance);
          },
        };
        if (isNil(typeList)) {
          defaultPropsModifyCompliance = {
            ...defaultPropsModifyCompliance,
            complianceId: id || 0,
            isSaveOnNextStep: true,
            isModifyComplianceMode: true,
            isShowCancelWorkflowButton: true,
          };
          return <ModifyComplianceDialog {...defaultPropsModifyCompliance} />;
        }
        //Using in workflow list
        defaultPropsModifyCompliance = {
          ...defaultPropsModifyCompliance,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <ModifyComplianceDialog {...defaultPropsModifyCompliance} />;
      case WorkflowTypes.Create_ChargeRun:
        let defaultPropsCreateChargeRun = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Create_ChargeRun);
          },
        };
        if (isNil(typeList)) {
          defaultPropsCreateChargeRun = {
            ...defaultPropsCreateChargeRun,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <CreateChargeRunDialog {...defaultPropsCreateChargeRun} />;
        }
        //Using in workflow list
        defaultPropsCreateChargeRun = {
          ...defaultPropsCreateChargeRun,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <CreateChargeRunDialog {...defaultPropsCreateChargeRun} />;
      case WorkflowTypes.Create_Deferment:
        let propsCreateDefermentDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Create_Deferment);
          },
        };
        if (isNil(typeList)) {
          propsCreateDefermentDialog = {
            ...propsCreateDefermentDialog,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <CreateDefermentDialog {...propsCreateDefermentDialog} />;
        }
        //Using in workflow list
        propsCreateDefermentDialog = {
          ...propsCreateDefermentDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <CreateDefermentDialog {...propsCreateDefermentDialog} />;
      case WorkflowTypes.Modify_Deferment:
        let defaultPropsModifyDeferment = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Modify_Deferment);
          },
        };
        if (isNil(typeList)) {
          defaultPropsModifyDeferment = {
            ...defaultPropsModifyDeferment,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
            defermentAssessmentId: gridSelectedRows?.[0]?.Id,
          };
          return <ModifyDefermentDialog {...defaultPropsModifyDeferment} />;
        }
        //Using in workflow list
        defaultPropsModifyDeferment = {
          ...defaultPropsModifyDeferment,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <ModifyDefermentDialog {...defaultPropsModifyDeferment} />;
      case WorkflowTypes.Journal_Assessment_Adjust_Charge:
        let propsJournalAssessmentAdjustCharge = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Journal_Assessment_Adjust_Charge);
          },
        };
        if (isNil(typeList)) {
          propsJournalAssessmentAdjustCharge = {
            ...propsJournalAssessmentAdjustCharge,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <AdjustChargeDialog {...propsJournalAssessmentAdjustCharge} />;
        }
        //Using in workflow list
        propsJournalAssessmentAdjustCharge = {
          ...propsJournalAssessmentAdjustCharge,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <AdjustChargeDialog {...propsJournalAssessmentAdjustCharge} />;
      case WorkflowTypes.Create_Entity:
        let propsCreateEntity = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Create_Entity);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsCreateEntity = {
            ...propsCreateEntity,
            isSaveOnNextStep: true,
            isNewContactMode: true,
            isShowCancelWorkflowButton: true,
          };
          return <NewContactDialog {...propsCreateEntity} />;
        }
        //Using in workflow list
        propsCreateEntity = {
          ...propsCreateEntity,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <NewContactDialog {...propsCreateEntity} />;
      case WorkflowTypes.Modify_Entity:
        let propsModifyEntity = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Modify_Entity);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsModifyEntity = {
            ...propsModifyEntity,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
            entityId: id || lastSelectedId,
          };
          return <UpdateContactDialog {...propsModifyEntity} />;
        }
        //Using in workflow list
        propsModifyEntity = {
          ...propsModifyEntity,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <UpdateContactDialog {...propsModifyEntity} />;
      case WorkflowTypes.Create_RebateClaim:
        let propsCreateRebateClaim = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Create_RebateClaim);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsCreateRebateClaim = {
            ...propsCreateRebateClaim,
            isSaveOnNextStep: true,
            isNewRebateClaimMode: true,
            isShowCancelWorkflowButton: true,
            rebateClaimId: id || 0,
          };
          return <NewRebateClaimDialog {...propsCreateRebateClaim} />;
        }
        //Using in workflow list
        propsCreateRebateClaim = {
          ...propsCreateRebateClaim,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <NewRebateClaimDialog {...propsCreateRebateClaim} />;
      case WorkflowTypes.Journal_DD_Reverse_Transaction:
        let defaultPropsJournalDDReverseTransaction = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Journal_DD_Reverse_Transaction);
          },
        };
        if (isNil(typeList)) {
          defaultPropsJournalDDReverseTransaction = {
            ...defaultPropsJournalDDReverseTransaction,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <DeferredDutyReverseTransactionDialog
              {...defaultPropsJournalDDReverseTransaction}
            />
          );
        }
        //Using in workflow list
        defaultPropsJournalDDReverseTransaction = {
          ...defaultPropsJournalDDReverseTransaction,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <DeferredDutyReverseTransactionDialog
            {...defaultPropsJournalDDReverseTransaction}
          />
        );
      case WorkflowTypes.Journal_DD_Transfer_Transaction:
        let defaultPropsJournalDDTransferTransaction = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Journal_DD_Transfer_Transaction);
          },
        };
        if (isNil(typeList)) {
          defaultPropsJournalDDTransferTransaction = {
            ...defaultPropsJournalDDTransferTransaction,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <TransferDDTransactionDialog
              {...defaultPropsJournalDDTransferTransaction}
            />
          );
        }
        //Using in workflow list
        defaultPropsJournalDDTransferTransaction = {
          ...defaultPropsJournalDDTransferTransaction,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <TransferDDTransactionDialog
            {...defaultPropsJournalDDTransferTransaction}
          />
        );
      case WorkflowTypes.Certificate_Create:
        let defaultPropsCertificateCreate = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Certificate_Create);
          },
        };
        if (isNil(typeList)) {
          defaultPropsCertificateCreate = {
            ...defaultPropsCertificateCreate,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
        } else {
          //Using in workflow list
          defaultPropsCertificateCreate = {
            ...defaultPropsCertificateCreate,
            ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
          };
        }
        return <NewCertificateDialog {...defaultPropsCertificateCreate} />;
      case WorkflowTypes.Journal_Assessment_Adjust_ChargeBalances:
        let defaultPropsJournalAssessmentAdjustChargeBalances = {
          ...props,
          onClose: () => {
            removeDialog(
              WorkflowTypes.Journal_Assessment_Adjust_ChargeBalances
            );
          },
        };
        if (isNil(typeList)) {
          defaultPropsJournalAssessmentAdjustChargeBalances = {
            ...defaultPropsJournalAssessmentAdjustChargeBalances,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <AdjustChargeBalancesDialog
              {...defaultPropsJournalAssessmentAdjustChargeBalances}
            />
          );
        }
        //Using in workflow list
        defaultPropsJournalAssessmentAdjustChargeBalances = {
          ...defaultPropsJournalAssessmentAdjustChargeBalances,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <AdjustChargeBalancesDialog
            {...defaultPropsJournalAssessmentAdjustChargeBalances}
          />
        );
      case WorkflowTypes.Journal_Authorise:
        let propsJournalAuthorise = {
          ...props,
          typeList: typeList,
          onClose: () => {
            removeDialog(WorkflowTypes.Journal_Authorise);
          },
        };
        if (isNil(typeList)) {
          propsJournalAuthorise = {
            ...propsJournalAuthorise,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <AuthoriseJournalDialog {...propsJournalAuthorise} />;
        }
        //Using in workflow list
        propsJournalAuthorise = {
          ...propsJournalAuthorise,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <AuthoriseJournalDialog {...propsJournalAuthorise} />;
      case WorkflowTypes.Certificate_Modify:
        let defaultPropsCertificateModify = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Certificate_Modify);
          },
        };
        if (isNil(typeList)) {
          defaultPropsCertificateModify = {
            ...defaultPropsCertificateModify,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
        } else {
          //Using in workflow list
          defaultPropsCertificateModify = {
            ...defaultPropsCertificateModify,
            ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
          };
        }
        return <ModifyCertificateDialog {...defaultPropsCertificateModify} />;
      case WorkflowTypes.Certificate_Replace:
        let defaultPropsCertificateReplace = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Certificate_Replace);
          },
        };
        if (isNil(typeList)) {
          defaultPropsCertificateReplace = {
            ...defaultPropsCertificateReplace,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
        } else {
          //Using in workflow list
          defaultPropsCertificateReplace = {
            ...defaultPropsCertificateReplace,
            ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
          };
        }
        return <ReplaceCertificateDialog {...defaultPropsCertificateReplace} />;
      case WorkflowTypes.Edit_Change_Of_Ownership:
        let propsEditChangeOwnershipDialog = {
          ...props,
          typeList: typeList,
          onClose: () => {
            removeDialog(WorkflowTypes.Edit_Change_Of_Ownership);
          },
        };
        if (isNil(typeList)) {
          propsEditChangeOwnershipDialog = {
            ...propsEditChangeOwnershipDialog,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <EditChangeOfOwnershipDialog {...propsEditChangeOwnershipDialog} />
          );
        }
        //Using in workflow list
        propsEditChangeOwnershipDialog = {
          ...propsEditChangeOwnershipDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <EditChangeOfOwnershipDialog {...propsEditChangeOwnershipDialog} />
        );
      case WorkflowTypes.AssessmentLandRentAction:
        let propsAssessmentLandRentActionDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.AssessmentLandRentAction);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsAssessmentLandRentActionDialog = {
            ...propsAssessmentLandRentActionDialog,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
            assessmentId: id,
          };
          return (
            <AssessmentLandRentActionDialog
              {...propsAssessmentLandRentActionDialog}
            />
          );
        }
        //Using in workflow list
        propsAssessmentLandRentActionDialog = {
          ...propsAssessmentLandRentActionDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <AssessmentLandRentActionDialog
            {...propsAssessmentLandRentActionDialog}
          />
        );
      case WorkflowTypes.Modify_ChargeDetail:
        let propsModifyChargeDetail = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Modify_ChargeDetail);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsModifyChargeDetail = {
            ...propsModifyChargeDetail,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <ModifyChargeDetailsDialog {...propsModifyChargeDetail} />;
        }
        //Using in workflow list
        propsModifyChargeDetail = {
          ...propsModifyChargeDetail,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <ModifyChargeDetailsDialog {...propsModifyChargeDetail} />;
      case WorkflowTypes.AreaOfResponsibility_Modify:
        let propsAreaOfResponsibilityModify = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.AreaOfResponsibility_Modify);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsAreaOfResponsibilityModify = {
            ...propsAreaOfResponsibilityModify,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
            certificateId: id,
          };
        } else {
          //Using in workflow list
          propsAreaOfResponsibilityModify = {
            ...propsAreaOfResponsibilityModify,
            ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
          };
        }
        return (
          <LoadWorkflowModifyAreaResponsibility
            {...propsAreaOfResponsibilityModify}
          />
        );
      case WorkflowTypes.BulkLevyUpdate:
        let propsBulkLevyUpdateDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.BulkLevyUpdate);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsBulkLevyUpdateDialog = {
            ...propsBulkLevyUpdateDialog,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <BulkLevyUpdateDialog {...propsBulkLevyUpdateDialog} />;
        }
        //Using in workflow list
        propsBulkLevyUpdateDialog = {
          ...propsBulkLevyUpdateDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <BulkLevyUpdateDialog {...propsBulkLevyUpdateDialog} />;
      case WorkflowTypes.Create_DebtRecoveryExemption:
        let propsCreateDebtRecoveryExemptionDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Create_DebtRecoveryExemption);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsCreateDebtRecoveryExemptionDialog = {
            ...propsCreateDebtRecoveryExemptionDialog,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <CreateDebtRecoveryExemptionDialog
              {...propsCreateDebtRecoveryExemptionDialog}
            />
          );
        }
        //Using in workflow list
        propsCreateDebtRecoveryExemptionDialog = {
          ...propsCreateDebtRecoveryExemptionDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <CreateDebtRecoveryExemptionDialog
            {...propsCreateDebtRecoveryExemptionDialog}
          />
        );
      case WorkflowTypes.Subdivisions_Consolidations:
        let propsSubdivideConsolidateDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Subdivisions_Consolidations);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsSubdivideConsolidateDialog = {
            ...propsSubdivideConsolidateDialog,
            assessmentId: id || 0,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <SubdivideConsolidateDialog {...propsSubdivideConsolidateDialog} />
          );
        }
        //Using in workflow list
        propsSubdivideConsolidateDialog = {
          ...propsSubdivideConsolidateDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <SubdivideConsolidateDialog {...propsSubdivideConsolidateDialog} />
        );
      case WorkflowTypes.NoticeRun_Create:
        let propsNewNoticeRunDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.NoticeRun_Create);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsNewNoticeRunDialog = {
            ...propsNewNoticeRunDialog,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <NewNoticeRunDialog {...propsNewNoticeRunDialog} />;
        }
        //Using in workflow list
        propsNewNoticeRunDialog = {
          ...propsNewNoticeRunDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <NewNoticeRunDialog {...propsNewNoticeRunDialog} />;
      case WorkflowTypes.PIC_Create:
        let propsNewPICDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.PIC_Create);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsNewPICDialog = {
            ...propsNewPICDialog,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <CreatePICDialog {...propsNewPICDialog} />;
        }
        //Using in workflow list
        propsNewPICDialog = {
          ...propsNewPICDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <CreatePICDialog {...propsNewPICDialog} />;
      case WorkflowTypes.PIC_Modify:
        let propsModifyPICDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.PIC_Modify);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsModifyPICDialog = {
            ...propsModifyPICDialog,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
            picId: +id || gridSelectedIds?.[0] || 0,
          };
          return <ModifyPICDialog {...propsModifyPICDialog} />;
        }
        //Using in workflow list
        propsModifyPICDialog = {
          ...propsModifyPICDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <ModifyPICDialog {...propsModifyPICDialog} />;
      case WorkflowTypes.Update_LossOfEligibilityDate:
        let defaultPropsUpdateLossOfEligibilityDate = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Update_LossOfEligibilityDate);
          },
        };
        if (isNil(typeList)) {
          defaultPropsUpdateLossOfEligibilityDate = {
            ...defaultPropsUpdateLossOfEligibilityDate,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <UpdateLossOfEligibilityDateDialog
              {...defaultPropsUpdateLossOfEligibilityDate}
            />
          );
        }
        //Using in workflow list
        defaultPropsUpdateLossOfEligibilityDate = {
          ...defaultPropsUpdateLossOfEligibilityDate,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <UpdateLossOfEligibilityDateDialog
            {...defaultPropsUpdateLossOfEligibilityDate}
          />
        );
      case WorkflowTypes.PIC_Reactivate:
        let propsReactivatePICDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.PIC_Reactivate);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsReactivatePICDialog = {
            ...propsReactivatePICDialog,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
            picId: +id || gridSelectedIds?.[0] || 0,
          };
          return <ReactivatePICDialog {...propsReactivatePICDialog} />;
        }
        //Using in workflow list
        propsReactivatePICDialog = {
          ...propsReactivatePICDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <ReactivatePICDialog {...propsReactivatePICDialog} />;
      case WorkflowTypes.ModifyGISReferenceTitle:
        let propsModifyGISReferenceTitleDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.ModifyGISReferenceTitle);
          },
        };
        if (isNil(typeList)) {
          propsModifyGISReferenceTitleDialog = {
            ...propsModifyGISReferenceTitleDialog,
            isShowCancelWorkflowButton: true,
          };
          return (
            <ModifyGISReferenceTitleDialog
              {...propsModifyGISReferenceTitleDialog}
            />
          );
        }
        //Using in workflow list
        propsModifyGISReferenceTitleDialog = {
          ...propsModifyGISReferenceTitleDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
          typeList: typeList,
        };
        return (
          <ModifyGISReferenceTitleDialog
            {...propsModifyGISReferenceTitleDialog}
          />
        );
      case WorkflowTypes.PIC_Amalgamate:
        let propsAmalgamatePICDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.PIC_Amalgamate);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsAmalgamatePICDialog = {
            ...propsAmalgamatePICDialog,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <AmalgamatePICDialog {...propsAmalgamatePICDialog} />;
        }
        //Using in workflow list
        propsAmalgamatePICDialog = {
          ...propsAmalgamatePICDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <AmalgamatePICDialog {...propsAmalgamatePICDialog} />;
      case WorkflowTypes.Reactivate_Assessment:
        let propsReactivateAssessment = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Reactivate_Assessment);
          },
        };
        if (isNil(typeList)) {
          propsReactivateAssessment = {
            ...propsReactivateAssessment,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <ReactivateAssessmentDialog {...propsReactivateAssessment} />;
        }
        //Using in workflow list
        propsReactivateAssessment = {
          ...propsReactivateAssessment,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <ReactivateAssessmentDialog {...propsReactivateAssessment} />;
      case WorkflowTypes.Associate_Assessment:
        let propsAssociateAssessment = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Associate_Assessment);
          },
        };
        if (isNil(typeList)) {
          propsAssociateAssessment = {
            ...propsAssociateAssessment,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <AssociateAssessmentDialog {...propsAssociateAssessment} />;
        }
        //Using in workflow list
        propsAssociateAssessment = {
          ...propsAssociateAssessment,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <AssociateAssessmentDialog {...propsAssociateAssessment} />;
      case WorkflowTypes.Disassociate_Assessment:
        let propsDisassociate_Assessment = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Disassociate_Assessment);
          },
        };
        if (isNil(typeList)) {
          propsDisassociate_Assessment = {
            ...propsDisassociate_Assessment,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <DisassociateAssessmentDialog {...propsDisassociate_Assessment} />
          );
        }
        //Using in workflow list
        propsDisassociate_Assessment = {
          ...propsDisassociate_Assessment,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <DisassociateAssessmentDialog {...propsDisassociate_Assessment} />
        );
      case WorkflowTypes.Amalgamate_Assessment:
        let propsAmalgamateAssessmentDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Amalgamate_Assessment);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsAmalgamateAssessmentDialog = {
            ...propsAmalgamateAssessmentDialog,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <AmalgamateAssessmentDialog {...propsAmalgamateAssessmentDialog} />
          );
        }
        //Using in workflow list
        propsAmalgamateAssessmentDialog = {
          ...propsAmalgamateAssessmentDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <AmalgamateAssessmentDialog {...propsAmalgamateAssessmentDialog} />
        );
      case WorkflowTypes.Split_Assessment:
        let propsSplitAssessmentDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Split_Assessment);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsSplitAssessmentDialog = {
            ...propsSplitAssessmentDialog,
            assessmentId: id || 0,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <SplitAssessmentDialog {...propsSplitAssessmentDialog} />;
        }
        //Using in workflow list
        propsSplitAssessmentDialog = {
          ...propsSplitAssessmentDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <SplitAssessmentDialog {...propsSplitAssessmentDialog} />;
      case WorkflowTypes.Journal_PIC_AddTransaction:
        let propsNewTransactionPICDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Journal_PIC_AddTransaction);
          },
        };
        ///Using in the button
        if (isNil(typeList)) {
          propsNewTransactionPICDialog = {
            ...propsNewTransactionPICDialog,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <NewTransactionPICDialog {...propsNewTransactionPICDialog} />;
        }
        //Using in workflow list
        propsNewTransactionPICDialog = {
          ...propsNewTransactionPICDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <NewTransactionPICDialog {...propsNewTransactionPICDialog} />;

      case WorkflowTypes.New_Rebate:
        let propsAddRebates = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.New_Rebate);
          },
        };
        if (isNil(typeList)) {
          propsAddRebates = {
            ...propsAddRebates,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <AddRebateDialog {...propsAddRebates} />;
        }
        //Using in workflow list
        propsAddRebates = {
          ...propsAddRebates,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <AddRebateDialog {...propsAddRebates} />;

      case WorkflowTypes.Change_Of_Ownership_NoticeOfSale:
        let defaultPropsUpdateNoticeOfSaleDialog = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Change_Of_Ownership_NoticeOfSale);
          },
        };
        if (isNil(typeList)) {
          defaultPropsUpdateNoticeOfSaleDialog = {
            ...defaultPropsUpdateNoticeOfSaleDialog,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return (
            <UpdateNoticeOfSaleDialog
              {...defaultPropsUpdateNoticeOfSaleDialog}
            />
          );
        }
        //Using in workflow list
        defaultPropsUpdateNoticeOfSaleDialog = {
          ...defaultPropsUpdateNoticeOfSaleDialog,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return (
          <UpdateNoticeOfSaleDialog {...defaultPropsUpdateNoticeOfSaleDialog} />
        );
      case WorkflowTypes.CreateTitle:
        let defaultPropsCreateTitle = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.CreateTitle);
          },
        };
        if (!typeList) {
          defaultPropsCreateTitle = {
            ...defaultPropsCreateTitle,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <CreateTitleDialog {...defaultPropsCreateTitle} />;
        }
        //Using in workflow list
        defaultPropsCreateTitle = {
          ...defaultPropsCreateTitle,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <CreateTitleDialog {...defaultPropsCreateTitle} />;
      case WorkflowTypes.Modify_Title:
        let defaultPropsModifyTitle = {
          ...props,
          onClose: () => {
            removeDialog(WorkflowTypes.Modify_Title);
          },
        };
        if (!typeList) {
          defaultPropsModifyTitle = {
            ...defaultPropsModifyTitle,
            isSaveOnNextStep: true,
            isShowCancelWorkflowButton: true,
          };
          return <ModifyTitleDialog {...defaultPropsModifyTitle} />;
        }
        //Using in workflow list
        defaultPropsModifyTitle = {
          ...defaultPropsModifyTitle,
          ...processPropsForWorkflowDialog(typeList, dialogEnum, data),
        };
        return <ModifyTitleDialog {...defaultPropsModifyTitle} />;
      default:
        return null;
    }
  };

  /**
   * take default props base on typeList
   */
  const processPropsForWorkflowDialog = (
    typeList: number,
    dialogEnum: WorkflowTypes,
    data?: any
  ) => {
    let defaultPropsDialog: any = { isFromActionList: true, typeList };
    if (!dialogEnum) return defaultPropsDialog;
    const { Workflow_Approval_Status } = data;
    switch (typeList) {
      case WorkflowStatus.Saved:
      case WorkflowStatus.Parked:
      case WorkflowStatus.Incomplete:
        defaultPropsDialog = {
          ...defaultPropsDialog,
          isSaveOnNextStep: true,
          dataFromActionList: data,
        };
        break;
      case WorkflowStatus.Completed:
        switch (Workflow_Approval_Status) {
          case WorkflowApprovalStatus.ToBeApproved:
          case WorkflowApprovalStatus.AwaitingGisApproval:
            defaultPropsDialog = {
              ...defaultPropsDialog,
              dataFromActionList: data,
              isSaveOnNextStep: true,
              prefixTitle: "Workflow Approval",
            };
            break;
          case WorkflowApprovalStatus.Approved:
            defaultPropsDialog = {
              ...defaultPropsDialog,
              dataFromActionList: data,
            };
            break;
          case WorkflowApprovalStatus.Rejected:
            defaultPropsDialog = {
              ...defaultPropsDialog,
              dataFromActionList: data,
            };
            break;
        }
        break;
      case WorkflowStatus.Cancelled:
        defaultPropsDialog = {
          ...defaultPropsDialog,
          dataFromActionList: data,
          suffixTitle: data?.[nameOfWorkflowDraft("Cancelled_Reason")],
        };
        break;
      // Will implement later when the advance search turn on
      // case TypesActionsWorkflow.AdvanceSearch:
      //   defaultPropsDialog = {
      //     ...defaultPropsDialog,
      //     //Currently, the same behavior with New Assessment
      //     isFromActionList: false,
      //     isSaveOnNextStep: true,
      //     isShowCancelWorkflowButton: true,
      //     statusBadge: data?.Workflow_Status_Name,
      //   };
      //   break;
      default:
        defaultPropsDialog = {
          ...defaultPropsDialog,
          onClose: () => {
            removeDialog(dialogEnum);
          },
        };
        break;
    }
    return defaultPropsDialog;
  };

  return (
    <>
      {listDialog?.length
        ? componentDialog({
            type: listDialog[0].type,
            props: listDialog[0].props,
            data: listDialog[0].data,
            typeList: listDialog[0].typeList,
          })
        : null}
      <ConfirmFinishDialog />
      <ConfirmCloseDialog />
      <ConfirmCancelDialog />
      <ConfirmSendBackDialog />
      <ConfirmRejectDialog />
      <ConfirmReallocateDialog />
    </>
  );
});
