import { EPrivilegesWorkflow } from "@app/products/property/model";
import {
  Privilege,
  ePermissionPrivilegeTypeCheck,
} from "@common/stores/products/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import React from "react";

interface IPrivilegeWrapperProps {
  children: React.ReactElement;
  dialogType: ePermissionPrivilegeTypeCheck;
  privilegesEnum: EPrivilegesWorkflow;
  shouldApplyWrapper?: boolean;
}

export const PrivilegeWrapper = ({
  shouldApplyWrapper = true,
  ...props
}: IPrivilegeWrapperProps) => {
  if (
    shouldApplyWrapper &&
    !Privilege.checkPermission(props.dialogType, props.privilegesEnum)
  ) {
    const handlePrivilegeOnclick = () => {
      appNotificationStore.pushNotification({
        title: "You do not have permission to perform this action.",
        type: "error",
        autoClose: false,
      });
    };
    return React.cloneElement(props.children, {
      onClick: handlePrivilegeOnclick,
    });
  }
  // If the condition is not met or the user has the required privileges, just return the child without modification
  return props.children as React.ReactElement;
};
