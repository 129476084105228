import { convertToFileStreamEntry } from "@app/core/attachment/components/dialogs/util";
import { Attachment, DOCUMENTSTATUS } from "@app/core/documents/model";
import { batchRecallInputValidators as validators } from "@app/core/recall/components/dialogs/batch-recall/util";
import {
  BATCH_RECALL_SEND_OPTIONS,
  BATCH_RECALL_STEP_KEYS as keys,
} from "@app/core/recall/constant";
import { OfficerPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/_index";
import { IOfficer } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import {
  requiredValidator,
  validatorDateValueWithMinMax,
} from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
import { UploadOnAddEvent } from "@progress/kendo-react-upload";
import React, { useCallback, useRef } from "react";
import "./_index.scss";

export const FormRecallStep = (props: IFormStepElement) => (
  <FieldArray name={props.nameOf()} {...props} component={FormRecall} />
);

const FormRecall = (props: IFormStepElement) => {
  //#region props, states & refs
  const { nameOf, formRenderProps, setStepsVisible, options } = props;
  const { onChange, valueGetter } = formRenderProps;
  const date = useRef(new Date());
  //#endregion

  //#region event handlers
  const handleChangeOfficer = (officer: IOfficer | null) => {
    // including removing the selection of officer
    onChange(nameOf("Officer_ID"), { value: officer?.ID });
    onChange("UIControl.Officer_Name", { value: officer?.Name });
  };

  const handleSwitchingSendOption = (event: RadioGroupChangeEvent) => {
    onChange("UIControl.SendOption", { value: event.value });
    setStepsVisible([
      {
        key: keys.CommunicationRecipient,
        visible: event.value === "communication",
      },
      {
        key: keys.CommunicationMessageTemplate,
        visible: event.value === "communication",
      },
      {
        key: keys.CommunicationMailMergeTemplate,
        visible: event.value === "communication",
      },
      {
        key: keys.CommunicationAttachment,
        visible: event.value === "communication",
      },
      {
        key: keys.CommunicationWorkflow,
        visible: event.value === "communication" && false,
      },
      {
        key: keys.MailMergeTemplate,
        visible: event.value === "mailmerge",
      },
      {
        key: keys.MailMergeOptions,
        visible: event.value === "mailmerge",
      },
      {
        key: keys.MailMergeWorkflow,
        visible: event.value === "mailmerge" && false,
      },
    ]);
  };

  const handleAttachments = async (event: UploadOnAddEvent) => {
    let processFile = [];
    for (let index = 0; index < event.newState.length; index++) {
      const fileEntry = await convertToFileStreamEntry(event.newState[index]);
      const attachments = {
        FileEntry: fileEntry,
        Title: event.newState[index]?.name ?? "",
        Category_KWD: null,
        Attachment_ID: 0,
        FileNumber: "",
        TRIMUri: "",
        Status_ENUM: DOCUMENTSTATUS.Draft,
      } as Attachment;
      processFile.push(attachments);
    }
    onChange(nameOf("ListOfAttachments"), { value: processFile });
    onChange("UIControl.AttachmentFiles", { value: event.newState });
  };
  //#endregion

  //#region Validators
  const validateRecallDate = useCallback((value: any) => {
    return (
      requiredValidator(value) ||
      validatorDateValueWithMinMax(value, null, date.current)
    );
  }, []);
  //#region

  //#region computed
  const areRecallsSaved = options?.recallIDs?.length > 0;
  //#endregion

  return (
    <section className="cc-form-recall-detail">
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Recall date" isMandatory />
            <Field
              name={nameOf("Date_Recall")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
              max={date.current}
              validator={validateRecallDate}
              disabled={areRecallsSaved}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Product type" />
            <Field
              name={nameOf("ProductType")}
              component={CCInput}
              disabled={areRecallsSaved}
              validator={
                areRecallsSaved ? undefined : validators.stringMaxLength150
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Product name" />
            <Field
              name={nameOf("ProductName")}
              component={CCInput}
              disabled={areRecallsSaved}
              validator={
                areRecallsSaved ? undefined : validators.stringMaxLength150
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Recall number" />
            <Field
              name={nameOf("Number")}
              component={CCInput}
              disabled={areRecallsSaved}
              validator={
                areRecallsSaved ? undefined : validators.stringMaxLength50
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Batch number" />
            <Field
              name={nameOf("BatchNo")}
              component={CCInput}
              disabled={areRecallsSaved}
              validator={
                areRecallsSaved ? undefined : validators.stringMaxLength150
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Country of origin" />
            <Field
              name={nameOf("CountryOfOrigin")}
              component={CCInput}
              disabled={areRecallsSaved}
              validator={
                areRecallsSaved ? undefined : validators.stringMaxLength150
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Marking date" />
            <Field
              name={nameOf("DateMarking")}
              component={CCInput}
              disabled={areRecallsSaved}
              validator={
                areRecallsSaved ? undefined : validators.stringMaxLength150
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Product size" />
            <Field
              name={nameOf("ProductSize")}
              component={CCInput}
              disabled={areRecallsSaved}
              validator={
                areRecallsSaved ? undefined : validators.stringMaxLength150
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Packaging" />
            <Field
              name={nameOf("ProductPackaging")}
              component={CCInput}
              disabled={areRecallsSaved}
              validator={
                areRecallsSaved ? undefined : validators.stringMaxLength150
              }
            />
          </div>
          <div className="cc-field cc-col-span-3">
            <CCLabel title="Description" />
            <Field
              name={nameOf("Details")}
              component={CCTextArea}
              rows={1}
              readOnly={areRecallsSaved}
            />
          </div>
          <div className="cc-field cc-col-span-3">
            <CCLabel
              title={
                "Attachment (" +
                (valueGetter("UIControl.AttachmentFiles")?.length ?? 0) +
                ")"
              }
            />
            <CCUploadFile
              autoUpload={false}
              batch={false}
              multiple
              files={valueGetter("UIControl.AttachmentFiles")}
              withCredentials={false}
              onAdd={handleAttachments}
              onRemove={handleAttachments}
              showActionButtons={false}
              disabled={areRecallsSaved}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Officer" />
            <Field
              name={"UIControl.Officer_Name"}
              component={OfficerPicker}
              title={"Pick Officer"}
              showClearButton
              onPickOfficer={handleChangeOfficer}
              disabled={areRecallsSaved}
            />
          </div>
          <div className="cc-field cc-col-span-3">
            <CCLabel title="Send" />
            <Field
              name="UIControl.SendOption"
              component={CCRadioGroup}
              data={BATCH_RECALL_SEND_OPTIONS}
              layout="horizontal"
              onChange={handleSwitchingSendOption}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
